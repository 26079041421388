import React from 'react'
import './InputField.css'

const InputField = ({
  value,
  onChange,
  placeholder,
  className = '',
  isRequired,
  drakStyle,
  readOnlyTrue,
  readOnly = 'readOnly',
  ariaLabel = 'Input',
}) => {
  const drakBg = drakStyle ? 'rgba(var(--black-code), 0.4)' : 'rgba(var(--white-code), 0.4)'
  return (
    <div className={`InputField ${className}`}>
      {/* <label>{label}</label> */}
      <input
        type="text"
        value={value}
        {...(readOnlyTrue ? null : (onChange = { onChange }))}
        placeholder={placeholder}
        className="custom-input"
        {...(isRequired ? { required: true } : {})}
        style={{ backgroundColor: drakBg }}
        {...(readOnlyTrue ? (readOnly = { readOnly }) : null)}
        aria-label={ariaLabel}
      />
      {isRequired ? <span className="isRequired">*</span> : null}
    </div>
  )
}

export default InputField
