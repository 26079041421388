const Pause = ({
  color = 'var(--green-business)',
  width = 30,
  className = '',
  fillColor = true,
}) => {
  const ratio = 34 / 34
  const height = width / ratio

  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 34">
        <path fill={color} d="M3 0h8.169v34H3V0ZM18.831 0H27v34h-8.169V0Z" />
      </svg>
    </div>
  )
}

export default Pause
