const AppleColorIcon = ({ width = 40, className = '' }) => {
  const ratio = 40 / 40
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <a
        aria-label="Go to Apple Podcast"
        href="https://podcasts.apple.com/lu/podcast/luxunplugged-podcast/id1484198880"
        target="_blanc"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
          <path
            fill="url(#AppleColorIcon)"
            fillRule="evenodd"
            d="M39.19 34.66a8.463 8.463 0 0 1-5.37 4.82 17.39 17.39 0 0 1-4.83.51H11.006a17.39 17.39 0 0 1-4.83-.51 8.46 8.46 0 0 1-5.37-4.82 15.677 15.677 0 0 1-.79-5.666V11.003a15.677 15.677 0 0 1 .79-5.667A8.463 8.463 0 0 1 6.176.52a17.389 17.389 0 0 1 4.83-.51h17.985a17.39 17.39 0 0 1 4.83.51 8.46 8.46 0 0 1 5.37 4.82c.61 1.822.879 3.742.794 5.663v17.99a15.678 15.678 0 0 1-.795 5.668Z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            d="M22.898 22.988A4.002 4.002 0 0 0 20 21.95a3.999 3.999 0 0 0-2.897 1.038 1.838 1.838 0 0 0-.53 1.152c-.08 1.007-.059 2.02.065 3.024.106 1.225.302 2.86.56 4.523.104.77.26 1.532.468 2.281a2.261 2.261 0 0 0 2.334 1.391 2.26 2.26 0 0 0 2.333-1.391c.208-.749.365-1.511.47-2.281.257-1.664.455-3.298.56-4.523.123-1.004.145-2.017.064-3.024a1.839 1.839 0 0 0-.53-1.152Zm-6.165-5.636a3.273 3.273 0 0 0 3.91 3.21 3.271 3.271 0 1 0-3.91-3.21Zm3.23-12.913a14.051 14.051 0 0 0-13.82 11.72 14.063 14.063 0 0 0 9.21 15.606.187.187 0 0 0 .238-.11.188.188 0 0 0 .01-.095 81.464 81.464 0 0 1-.203-1.44.485.485 0 0 0-.285-.381 12.258 12.258 0 0 1-7.109-13.74 12.26 12.26 0 0 1 12-9.763 12.253 12.253 0 0 1 11.998 9.762 12.264 12.264 0 0 1-7.11 13.74.483.483 0 0 0-.282.382c-.063.475-.132.954-.204 1.44a.188.188 0 0 0 .25.204 14.054 14.054 0 0 0 9.201-15.647A14.06 14.06 0 0 0 19.963 4.438v.001Zm-.254 6.45a7.611 7.611 0 0 1 7.33 4.667 7.618 7.618 0 0 1-1.814 8.5.583.583 0 0 0-.184.457c.027.535.021 1.071-.016 1.605a.185.185 0 0 0 .289.165 9.418 9.418 0 0 0 3.7-10.559 9.42 9.42 0 0 0-8.999-6.646 9.413 9.413 0 0 0-8.998 6.646 9.422 9.422 0 0 0 3.7 10.559.185.185 0 0 0 .287-.165 13.474 13.474 0 0 1-.016-1.606.582.582 0 0 0-.184-.45 7.617 7.617 0 0 1 4.907-13.168l-.002-.005Z"
          />
          <defs>
            <linearGradient
              id="AppleColorIcon"
              x1="20"
              x2="20"
              y1="0"
              y2="40"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F452FF" />
              <stop offset="1" stopColor="#832BC1" />
            </linearGradient>
          </defs>
        </svg>
      </a>
    </div>
  )
}

export default AppleColorIcon
