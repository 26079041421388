import './MobileMenu.css'
import { NavLink } from 'react-router-dom'
import { useStore } from '../../store.js'

const MobileMenu = ({ color = 'var(--red-home)', className = '' }) => {
  const toggleShowMenuMobile = useStore((state) => state.toggleShowMenuMobile)
  return (
    <nav className={`MobileMenu ${className}`}>
      <ul className="podcast-list">
        <li onClick={toggleShowMenuMobile}>
          <NavLink to="/*">ALL PODCAST</NavLink>
        </li>
        <li onClick={toggleShowMenuMobile}>
          <NavLink to="/business-podcast">BUSINESS</NavLink>
        </li>
        <li onClick={toggleShowMenuMobile}>
          <NavLink to="/net-zero-future-podcast">NET ZERO FUTURE</NavLink>
        </li>
        <li onClick={toggleShowMenuMobile}>
          <NavLink to="/dark-matter-uncovered-podcast">DARK MATTER UNCOVERED</NavLink>
        </li>
        <li onClick={toggleShowMenuMobile}>
          <NavLink to="/about">ABOUT US</NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default MobileMenu
