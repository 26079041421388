import './Modal.css'
import CloseButton from '../CloseButton/CloseButton'

const Modal = ({
  children,
  modalClosed,
  setModalClosed,
  makeCookie,
  cookiesNames,
  cookiesAllowed,
  className = '',
  isModalNoCookies,
  toggleModaldNoCookies,
}) => {
  const closeModal = () => {
    if (cookiesAllowed === true) {
      makeCookie(cookiesNames.modalClosed, 'true')
    }
    setModalClosed(true)
  }

  const closeModalNoCookies = () => {
    toggleModaldNoCookies(true)
  }

  return (
    <div
      className={`Modal-wrapper ${className} ${
        modalClosed === null || modalClosed === true || isModalNoCookies === true
          ? 'CloseModal'
          : ''
      }`}
    >
      <div className="Modal">
        <span
          className="close-btn"
          onClick={modalClosed !== undefined ? closeModal : closeModalNoCookies}
        >
          <CloseButton />
        </span>
        <div className="Modal-content">{children}</div>
      </div>
      <div
        className="Modal-bg"
        onClick={modalClosed !== undefined ? closeModal : closeModalNoCookies}
      ></div>
    </div>
  )
}

export default Modal
