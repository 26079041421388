import Pause from '../_SVG/Pause'
import Triangular from '../_SVG/Triangular'
import React, { useEffect } from 'react'
import './PlayButton.css'

const PlayButton = ({
  colorPrimary = 'var(--violet-business)',
  colorSecondary = 'var(--green-business)',
  width = '',
  border = true,
  onClick,
  isIconClicked,
}) => {
  useEffect(() => {}, [isIconClicked])
  const height = width
  return (
    <div
      onClick={onClick}
      className="PlayButton"
      style={{
        height: height,
        width: width,
        backgroundColor: border === true ? 'none' : colorSecondary,
        border: border === true ? `2px solid ${colorPrimary}` : 'none',
      }}
    >
      {isIconClicked ? (
        <Pause width={24} className="Pause" color={colorPrimary} />
      ) : (
        <Triangular className="Triangular" width={width / 3} color={colorPrimary} />
      )}
    </div>
  )
}

export default PlayButton
