import Page from './_Page/Page.jsx'
import Section from '../sections/_Section/Section.jsx'
import HeroSection from '../sections/HeroSection/HeroSection.jsx'
import PlayerSection from '../sections/PlayerSection/PlayerSection.jsx'
import DescriptionSection from '../sections/DescriptionSection/DescriptionSection.jsx'
import MusicPlatformsSection from '../sections/MusicPlatformsSection/MusicPlatformsSection.jsx'
import FeaturedEpisodes from '../sections/FeaturedEpisodes/FeaturedEpisodes.jsx'
import AllEpisodes from '../sections/AllEpisodesSection/AllEpisodes.jsx'
import Partners from '../sections/PartnersSection/Partners.jsx'
import FooterSection from '../sections/FooterSection/FooterSection.jsx'
import MotionAnimation from '../components/Transition/MotionAnimation.jsx'
import { Helmet } from 'react-helmet-async'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { getColorsBySeriesName } from '../cross-page-functions.js'

const LUXUNPLUGGED_API = import.meta.env.VITE_PATH_TO_API

const BusinessPodcastPage = ({
  colorPrimary,
  colorSecondary,
  previousColor,
  pathName,
  setEpisodeId,
}) => {
  const [episodeInfo, setEpisodeInfo] = useState({
    formattedTitle: null,
    guest: null,
    series: null,
    episodeNumber: null,
    iframeurl: null,
    primaryColor: null,
    secondaryColor: null,
    id: null,
    description: null,
  })
  const [FeaturedEpisodesList, setFeaturedEpisodes] = useState(false)
  const [episodeInfoLoaded, setEpisodeInfoLoaded] = useState(false)
  const [isFavicon, setFavicon] = useState('/favicon-business.svg')

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    async function buffer() {
      // getting information about the podcast by its id
      axios
        .get(LUXUNPLUGGED_API + '/last-episode/business_podcast?formatted=true')
        .then((res) => {
          if (res.status == 200) {
            let [primary, secondary] = getColorsBySeriesName(res.data.data.series)
            setEpisodeInfo((currentEpisodeInfo) => {
              currentEpisodeInfo.episodeNumber = res.data.data.episode_number
              currentEpisodeInfo.formattedTitle = res.data.data.title
              currentEpisodeInfo.guest = res.data.data.guest
              currentEpisodeInfo.series = res.data.data.series
              currentEpisodeInfo.iframeurl = res.data.data.url.iframe
              currentEpisodeInfo.primaryColor = primary
              currentEpisodeInfo.secondaryColor = secondary
              currentEpisodeInfo.id = res.data.data.id
              currentEpisodeInfo.description = res.data.data.description

              return currentEpisodeInfo
            })
            setEpisodeId(res.data.data.id)

            setEpisodeInfoLoaded(true)
          }
        })
        .catch((err) => console.error(err))

      axios
        .get(LUXUNPLUGGED_API + '/featured-episodes/business_podcast')
        .then((res) => {
          if (res.status == 200) {
            setFeaturedEpisodes(res.data.data)
          }
        })
        .catch((err) => console.error(err))
    }

    buffer()
  }, [])

  useEffect(() => {
    if (
      episodeInfo.formattedTitle !== null &&
      FeaturedEpisodesList !== false &&
      episodeInfoLoaded !== false
    ) {
      setLoaded(true)
      // console.log('loaded')
    }
  }, [FeaturedEpisodesList, episodeInfo, episodeInfoLoaded])

  const favicon =
    document.querySelector("link[rel='icon']") ||
    document.querySelector("link[rel='shortcut icon']")

  useEffect(() => {
    favicon.href = '/favicon-business.svg'
  }, [location.pathname, episodeInfo.series])
  return (
    <>
      <Helmet>
        <title>LuxUnplugged - Business Podcast</title>{' '}
        <meta
          name="description"
          content="Luxembourg’s business ecosystem with LuxUnplugged Business Podcast: Delve into entrepreneurship, innovation, logistics, and market trends with founders, experts & researchers."
          data-rh="true"
        />
        <meta
          name="keywords"
          content="Luxunplugged Business Podcast Luxembourg, Business Insights, Entrepreneurship in Luxembourg, Logistics trends in Luxembourg business, Innovation and Tech in Business, Adrien Loesch, Thierry Schoen, Market Trends, Financial Business Ecosystem, Luxembourg success stories"
        />
      </Helmet>
      <Page className="BusinessPodcastPage" colorPrimary={colorPrimary} pathName={pathName}>
        <MotionAnimation color={colorPrimary} previousColor={previousColor} />
        <HeroSection colorPrimary={colorPrimary} colorSecondary={colorSecondary} title="Business" />
        <PlayerSection
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
          loaded={loaded}
          iframeurl={episodeInfo.iframeurl}
          guestName={episodeInfo.guest}
          episodeNumber={episodeInfo.episodeNumber}
          id={episodeInfo.id}
        />
        <DescriptionSection
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
          pathName={pathName}
          descriptionText={episodeInfo.description}
          episodeNumber={episodeInfo.episodeNumber}
          id={episodeInfo.id}
        />
        <Section color={colorPrimary} CirclesCropped overflowType={'hidden'}>
          <MusicPlatformsSection backgroundColor={colorPrimary} />
        </Section>
        <Section colorPrimary={colorPrimary} sectionTitle={'THE BEST'} className="pb-8">
          {FeaturedEpisodesList && (
            <FeaturedEpisodes
              colorSecondary={colorSecondary}
              FeaturedEpisodesList={FeaturedEpisodesList}
            />
          )}
        </Section>
        <Section
          colorPrimary={colorPrimary}
          color={'var(--white)'}
          className="AllEpisodesSection pb-8"
          sectionTitle={'NON STOP'}
          overflowType="hidden"
        >
          <AllEpisodes colorSecondary={colorSecondary} parentPage="business_podcast" />
        </Section>

        <Section
          colorPrimary={colorPrimary}
          color={'var(--white)'}
          className="Partners"
          sectionTitle={'ALL TOGETHER'}
        >
          <Partners colorSecondary={colorSecondary} title="Our Partners" />
        </Section>

        <FooterSection />
      </Page>
    </>
  )
}

export default BusinessPodcastPage
