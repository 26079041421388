import './SoundwaveLine.css'

const SoundwaveLine = ({
  color = 'var(--red-home)',
  width = 40,
  lineWidth = '20px',
  lineTop = '9px',
  strokeWidth = '2px',
  className = '',
}) => {
  const ratio = 33 / 15
  const height = width / ratio
  return (
    <>
      <style>
        {`
      @media (max-width: 576px){  
        .SoundwaveLine {
        transform-origin: left center;
        transform: scale(0.8);
        }
      }
    `}
      </style>
      <div className={`SVG SoundwaveLine ${className}`} style={{ width: width, height: height }}>
        <div
          id="line"
          style={{ backgroundColor: color, width: lineWidth, left: width - 3, top: lineTop }}
        ></div>
        <svg
          viewBox="0 0 33 15"
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 8.07017C1 8.07017 2.56945 8.07017 7.80804 8.07017C13.0466 8.07017 11.7471 1 13.8151 1C15.8832 1 15.121 13.1109 17.3236 13.1109C19.5262 13.1109 18.6135 8.07017 21.5678 8.07017"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
          />
          <path
            d="M21.6519 8.0762C25.0883 8.11941 31.1196 8.0762 31.1196 8.0762"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            vectorEffect="non-scaling-stroke"
          />
        </svg>
      </div>
    </>
  )
}

export default SoundwaveLine
