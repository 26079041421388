import TeamCircles from '../_SVG/TeamCircles'
import './TeamMember.css'

const TeamMember = ({
  isCloseModal,
  children,
  circleOrder = '0',
  imgPerson = 'Adrien',
  imgAlt = 'Adrien Loesch',
  imgTop = '-20px',
  imgLeft = '-30px',
  imgTransform = 'scale(0.8)',
  circlesColor = 'var(--green-business)',
  circlesRotation = '90deg',
  backgroundColor = 'rgba(var(--green-business-code), 0.2)',
  lineDirection = '-50%',
  className = '',
}) => {
  return (
    <div className={`TeamMember ${isCloseModal ? 'CloseModal' : ''}`}>
      <div className={`circles-wrapper ${className}`} style={{ order: circleOrder }}>
        <div className="img-wrapper" style={{ backgroundColor: backgroundColor }}>
          <img
            src={`./img/${imgPerson}.png`}
            alt={imgAlt}
            style={{ top: imgTop, left: imgLeft, transform: imgTransform }}
          />
        </div>
        <TeamCircles color={circlesColor} rotate={circlesRotation} />
        <span
          className="circle-line"
          style={{ transform: `translateX(${lineDirection})`, backgroundColor: circlesColor }}
        ></span>
      </div>
      <div className="member-info">{children}</div>
    </div>
  )
}

export default TeamMember
