import CircleQuarter from '../_SVG/CircleQuarter'
import FacebookIcon from '../_SVG/FacebookIcon'
import InstagramIcon from '../_SVG/InstagramIcon'
import LinkedInIcon from '../_SVG/LinkedInIcon'
import XIcon from '../_SVG/XIcon'
import './RoundButton.css'

const RoundButton = ({
  color = 'var(--red-home)',
  iconSize = 24,
  className = '',
  icon = 'InstagramIcon',
  href = 'https://www.instagram.com/luxunplugged/',
  ariaLabel = 'Button',
}) => {
  return (
    <button
      type="button"
      aria-label={ariaLabel}
      className={`RoundButton ${className}`}
      style={{ backgroundColor: color }}
    >
      <a href={href} aria-label={ariaLabel} target="_blanc">
        {icon === 'InstagramIcon' ? <InstagramIcon width={iconSize} /> : null}
        {icon === 'LinkedInIcon' ? <LinkedInIcon width={iconSize} /> : null}
        {icon === 'XIcon' ? <XIcon width={iconSize} /> : null}
        {icon === 'FacebookIcon' ? <FacebookIcon width={iconSize} /> : null}
        <div className="outline">
          <span>
            <CircleQuarter strokeWidth={3} rotate="0deg" />
            <CircleQuarter opacity="0" strokeWidth={3} rotate="-90deg" />
          </span>
          <span>
            <CircleQuarter strokeWidth={3} rotate="-270deg" />
            <CircleQuarter strokeWidth={3} rotate="180deg" />
          </span>
        </div>
      </a>
    </button>
  )
}

export default RoundButton
