import React, { useState, useEffect } from 'react'
import InputField from '../InputField/InputField'
import Button from '../Button/Button'
import CopyIcon from '../_SVG/CopyIcon'
import './Share.css'

const Share = ({ isModalShare, episodeId }) => {
  const [isClipboard, toggleClipboard] = useState(false)
  const [actualUrlToCopy, setUrlToCopy] = useState('')
  let urlToCopy = ''

  useEffect(() => {
    if (episodeId != null) {
      urlToCopy = episodeId
      urlToCopy = window.location.href
      let indexOfSlash = urlToCopy.indexOf('/', 10)

      urlToCopy = urlToCopy.slice(0, indexOfSlash) + '/episode/' + episodeId

      setUrlToCopy(urlToCopy)
    }
  }, [episodeId])

  const closeModalNoCookies = (e) => {
    isModalShare === e
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(actualUrlToCopy)
    toggleClipboard(true)
    closeModalNoCookies(false)
    //console.log(currentUrl, 'currentUrl')
  }

  useEffect(() => {
    closeModalNoCookies(true)
    toggleClipboard(false)
  }, [isModalShare])

  //onsole.log(currentUrl, isClipboard, isModalShare, 'currentUrl')
  return (
    <div className="Share mb-3">
      <h2>Share this link:</h2>
      <div className="input-with-button">
        <InputField value={actualUrlToCopy} readOnlyTrue ariaLabel="Share the link" />

        <Button
          onClick={copyToClipboard}
          ariaLabel={'Share the podcast link'}
          text={'Copy'}
          noText={'noText'}
        >
          <CopyIcon className={'me-0 me-md-2 me-xxl-2'} />
        </Button>
      </div>
      <span className={`info ${isClipboard === true ? 'active' : ''}`}>
        {isClipboard === true && isModalShare === false ? 'Text copied to clipboard' : ''}
      </span>
    </div>
  )
}

export default Share
