import './CirclesCroppedGroup.css'

const CirclesCroppedGroup = ({
  trueColor,
  color = 'var(--black)',
  width = '1359',
  className = '',
  circlesOpacity = 0.4,
  bgCircleOpacity = '0',
  bgCircleFill = 'var(--black)',
  rotateG1 = '0deg',
  rotateG2 = '-20deg',
  rotateG3 = '0deg',
  id = '',
}) => {
  const ratio = 1359 / 1359
  const height = width / ratio + 'px'
  trueColor = true
  return (
    <div
      className={`SVG CirclesCroppedGroup ${className}`}
      style={{ width: width, height: height, opacity: circlesOpacity }}
    >
      <svg viewBox="0 0 1359 1359" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="c1g" style={{ transform: `rotate(${rotateG1})`, transformOrigin: 'center' }}>
          <path
            d="M859.9 24.3L859.4 24.5L848.9 62.6C1119.5 137 1318.4 384.8 1318.4 679C1318.4 1032 1032.2 1318.2 679.2 1318.2C620.4 1318.2 563.5 1310.3 509.4 1295.4L498.9 1333.5H497.4C555.3 1349.5 616.2 1358.1 679.2 1358.1C1054.3 1358.1 1358.3 1054 1358.3 679C1358.2 366.6 1147.2 103.5 859.9 24.3Z"
            fill={`url(#c1f${id})`}
          />
          <path
            id="c1b"
            opacity={bgCircleOpacity}
            d="M679.1 0C304.1 0 0 304.1 0 679.1C0 1054.1 304.1 1358.2 679.1 1358.2C1054.1 1358.2 1358.2 1054.1 1358.2 679.1C1358.2 304.1 1054.2 0 679.1 0ZM679.1 1318.3C326.1 1318.3 39.9 1032.1 39.9 679.1C39.9 326.1 326.1 39.9 679.1 39.9C1032.1 39.9 1318.3 326.1 1318.3 679.1C1318.3 1032.1 1032.1 1318.3 679.1 1318.3Z"
            fill={bgCircleFill}
          />
        </g>
        <g id="c2g" style={{ transform: `rotate(${rotateG2})`, transformOrigin: 'center' }}>
          <path
            d="M759 63.5L758.9 63.7L753.8 103.2C1039.3 139.8 1259.9 383.7 1259.9 679.2C1259.9 999.9 999.9 1259.9 679.2 1259.9C653.9 1259.9 629 1258.3 604.6 1255.1L599.5 1294.6L599.4 1294.8C625.5 1298.2 652.2 1299.9 679.3 1299.9C1022.1 1299.9 1300 1022 1300 679.2C1299.9 363.4 1064.1 102.7 759 63.5Z"
            fill={`url(#c2f${id})`}
          />
          <path
            id="c2b"
            opacity={bgCircleOpacity}
            d="M679.1 58.4C336.3 58.4 58.3999 336.3 58.3999 679.1C58.3999 1021.9 336.3 1299.8 679.1 1299.8C1021.9 1299.8 1299.8 1021.9 1299.8 679.1C1299.8 336.3 1021.9 58.4 679.1 58.4ZM679.1 1259.9C358.4 1259.9 98.3999 999.9 98.3999 679.2C98.3999 358.5 358.4 98.5 679.1 98.5C999.8 98.5 1259.8 358.5 1259.8 679.2C1259.8 999.9 999.9 1259.9 679.1 1259.9Z"
            fill={bgCircleFill}
          />
        </g>
        <g id="c3g" style={{ transform: `rotate(${rotateG3})`, transformOrigin: 'center' }}>
          <path
            d="M978.6 202.9L978.5 203L957.2 236.8C1104 329.3 1201.5 492.8 1201.5 679.1C1201.5 967.6 967.6 1201.4 679.2 1201.4C577 1201.4 481.7 1172.1 401.2 1121.4L379.8 1155.2L379.6 1155.3C466.3 1210 569 1241.6 679.1 1241.6C989.8 1241.6 1241.6 989.8 1241.6 679.1C1241.6 478.5 1136.6 302.5 978.6 202.9Z"
            fill={`url(#c3f${id})`}
          />

          <path
            id="c3b"
            opacity={bgCircleOpacity}
            d="M679.1 116.6C368.4 116.6 116.6 368.4 116.6 679.1C116.6 989.8 368.4 1241.6 679.1 1241.6C989.8 1241.6 1241.6 989.8 1241.6 679.1C1241.6 368.4 989.8 116.6 679.1 116.6ZM679.1 1201.5C390.6 1201.5 156.8 967.6 156.8 679.2C156.8 390.8 390.7 156.9 679.1 156.9C967.5 156.9 1201.4 390.8 1201.4 679.2C1201.4 967.6 967.6 1201.5 679.1 1201.5Z"
            fill={bgCircleFill}
          />
        </g>
        <defs>
          <linearGradient
            id={`c1f${id}`}
            x1="876.928"
            y1="1293.27"
            x2="1117.7"
            y2="97.0472"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color} stopOpacity="0" />
            <stop offset="1" stopColor={color} />
          </linearGradient>
          <linearGradient
            id={`c2f${id}`}
            x1="599.258"
            y1="681.665"
            x2="1299.86"
            y2="681.665"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color} stopOpacity="0" />
            <stop offset="1" stopColor={color} />
          </linearGradient>
          <linearGradient
            id={`c3f${id}`}
            x1="379.619"
            y1="722.247"
            x2="1241.61"
            y2="722.247"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={color} stopOpacity="0" />
            <stop offset="1" stopColor={color} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default CirclesCroppedGroup
