const LinkedInIcon = ({ color = 'var(--white)', width = 24, className = '' }) => {
  const ratio = 24 / 24
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25">
        <path
          fill={color}
          d="M5.567 24.44H.592V8.415h4.975v16.023ZM3.077 6.23C1.486 6.23.195 4.912.195 3.321a2.882 2.882 0 0 1 5.764 0c0 1.591-1.292 2.909-2.882 2.909ZM24.19 24.44h-4.965v-7.8c0-1.86-.038-4.244-2.587-4.244-2.587 0-2.983 2.02-2.983 4.11v7.933h-4.97V8.416h4.771V10.6h.07c.664-1.258 2.287-2.587 4.708-2.587 5.036 0 5.961 3.316 5.961 7.623v8.802h-.005Z"
        />
      </svg>
    </div>
  )
}

export default LinkedInIcon
