import { Link } from 'react-router-dom'
import './PlayerSection.css'
import EpisodeCard from '../../components/EpisodeCard/EpisodeCard'
import Soundwave from '../../components/_SVG/Sounwave'
import { useStore } from '../../store'
import Tag from '../../components/Tag/Tag'
import { images_list_by_id } from '../../images'

const PlayerSection = ({
  colorPrimary = '',
  colorSecondary = '',
  className = '',
  isHomeBg = false,
  iframeurl = '',
  loaded = false,
  episodeNumber = '',
  guestName,
  id = null,
}) => {
  // const toggleModal = useStore((state) => state.toggleModal)
  // const isCloseModalShare = useStore()
  const toggleModalShare = useStore((state) => state.toggleModalShare)

  let imagesrc = ''
  let nocover = false
  if (
    id != null &&
    images_list_by_id[id] != undefined &&
    images_list_by_id[id].img_src != undefined
  ) {
    imagesrc = '/img/cover/' + images_list_by_id[id].img_src
  } else {
    nocover = true
  }

  if (loaded) {
    return (
      <div style={{ backgroundColor: isHomeBg ? 'var(--grey-home)' : colorPrimary }}>
        {loaded === true ? (
          <div className={`PlayerSection ${className} position-relative`}>
            <Link to={'/episode/' + id} aria-label="Go to the episode">
              <EpisodeCard
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                insidePlayer
                imageAlt={guestName}
                noCoverImage={nocover}
                imageSrc={imagesrc}
                name={guestName}
                episodeNumber={episodeNumber}
              />
            </Link>
            <div
              className="iframe-wrapper w-100 overflow-hidden d-flex"
              style={{ backgroundColor: 'var(--player)' }}
            >
              <Tag
                className="z-2"
                colorPrimary={colorPrimary}
                style={{ top: '0.25rem', right: '-0.3rem', transform: 'scale(0.8)' }}
              />
              <div className="soundwave mb-4">
                <Soundwave className="Soundwave" width={'100%'} height={'80px'} />
              </div>
              <div className="player-footer">
                <ul className="p-0 m-0" style={{ color: colorSecondary }}>
                  {/* <li className="me-3" onClick={toggleModal}>
                    Subscribe
                  </li> */}
                  <li onClick={toggleModalShare}>Share</li>
                </ul>
              </div>
              <iframe
                title="Listen LuxUnplugged Podcast"
                id="myIframe"
                frameBorder="no"
                scrolling="no"
                seamless
                src={iframeurl}
              ></iframe>
            </div>
          </div>
        ) : null}
      </div>
    )
  } else {
    return (
      <div
        className="w-100"
        style={{ backgroundColor: isHomeBg ? 'var(--grey-home)' : colorPrimary }}
      >
        <div className={`PlayerSection preview ${className} position-relative`}>
          <EpisodeCard colorPrimary={colorPrimary} colorSecondary={colorSecondary} insidePlayer />

          <div className="player px-5 py-4">
            <div className="soundwave loading w-100">
              <span>LOADING</span>
              <Soundwave className="Soundwave" width={'100%'} height={'80px'} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PlayerSection
