import React from 'react'
import './AboutDescriptionSection.css'
import H3Title from '../../components/H3Tittle/H3Title'
import { Link } from 'react-router-dom'
import SoundwaveLine from '../../components/_SVG/SoundwaveLine'

const AboutDescriptionSection = ({ colorPrimary = '', colorSecondary = '', isHomeColor }) => {
  return (
    <section id="AboutDescriptionSection">
      <div className="container position-relative">
        <div className="row">
          <div className="col-lg-12 col-xxl-3">
            <H3Title isHomeColor text="About project" />
          </div>
          <div className="col-lg-9 col-xxl-6 col-sm-12">
            <p className="description">
              Adrien and Thierry had the idea for LuxUnplugged in 2019 while brainstorming in an
              east London pub on how best to promote Luxembourg outside its own geographical
              borders. Since then, LuxUnplugged has grown with new members joining the team and with
              the show adding series dedicated to space (Dark Matter Uncovered) and climate (Net
              Zero Future) in 2022.
            </p>
          </div>
          <div className="col-lg-3 col-xxl-3 col-sm-12">
            <div className="podcast-links mb-2">
              <ul>
                <li>LISTEN EVERYWHERE</li>
                <li>
                  <Link
                    target="_blank"
                    to="https://podcasts.apple.com/lu/podcast/luxunplugged-podcast/id1484198880"
                  >
                    <span>Apple Podcast</span>
                    <SoundwaveLine color={colorSecondary} className="ms-2" lineWidth={20} />
                  </Link>
                </li>
                <li>
                  <Link
                    target="_blank"
                    to="https://open.spotify.com/show/7MjrCTEV18O23rADaqUKwT?si=PGuPCOqLTGCxqdNH1IZEIQ&nd=1"
                  >
                    <span>Spotify</span>
                    <SoundwaveLine color={colorSecondary} className="ms-2" lineWidth={20} />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://www.deezer.com/en/show/663732">
                    <span>Deezer</span>
                    <SoundwaveLine color={colorSecondary} className="ms-2" lineWidth={20} />
                  </Link>
                </li>
                <li>
                  <Link target="_blank" to="https://soundcloud.com/luxunplugged">
                    <span>Soundcloud</span>
                    <SoundwaveLine color={colorSecondary} className="ms-2" lineWidth={20} />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutDescriptionSection
