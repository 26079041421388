import EpisodeList from '../../components/EpisodeList/EpisodeList'
import H3Title from '../../components/H3Tittle/H3Title'
import Button from '../../components/Button/Button'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { getColorsBySeriesName } from '../../cross-page-functions'

const LUXUNPLUGGED_API = import.meta.env.VITE_PATH_TO_API

const AllEpisodes = ({ colorSecondary = '', isHomeColor, parentPage }) => {
  const [page, setPage] = useState(1)
  const [allEpisodesList, setAllEpisodesList] = useState([])

  async function updatePodcastList() {
    if (page == false) {
      return
    }
    let fail = false
    await axios
      .get(LUXUNPLUGGED_API + '/get-last-episodes/' + parentPage + '/' + page)
      .then((res) => {
        if (res.status == 200) {
          let new_episodes_list = res.data.data
          let old_episodes_list = allEpisodesList
          new_episodes_list.map((episode) => {
            let temp = {}
            let [primary, secondary] = getColorsBySeriesName(episode.series)
            temp.episodeNumber = episode.episode_number
            temp.formattedTitle = episode.title
            temp.guest = episode.guest
            temp.series = episode.series
            temp.iframeurl = episode.url.iframe
            temp.id = episode.id
            temp.primaryColor = primary
            temp.secondaryColor = secondary
            temp.date = episode.created_at

            if (!old_episodes_list.includes(temp)) {
              old_episodes_list.push(temp)
            }
          })

          setAllEpisodesList(old_episodes_list)
        } else {
          fail = true
          return
        }
      })
      .catch((err) => {
        console.log(err)
        fail = true
      })
    if (fail == true) {
      setPage(false)
    } else {
      setPage(page + 1)
    }
  }

  useEffect(() => {
    updatePodcastList()
  }, [])
  return (
    <div className="container position-relative">
      <div className="row">
        <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12">
          <H3Title isHomeColor={isHomeColor} colorSecondary={colorSecondary} text="All Episodes" />
        </div>
        <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12" key="a">
          {allEpisodesList.map((episode, index) => {
            return (
              <EpisodeList
                colorPrimary={episode.primaryColor}
                title={episode.formattedTitle}
                name={episode.guest}
                number={episode.episodeNumber}
                index={index}
                key={index}
                episodeId={episode.id}
                date={episode.date}
              />
            )
          })}
        </div>
      </div>
      <div className="row">
        <div className="col-xl-9 offset-xl-3 col-lg-12 col-md-12 col-sm-12">
          <Button
            ariaLabel={'Show More'}
            className="mt-5"
            secondary
            color="var(--black)"
            text={'Show More'}
            fixedWidth={'200px'}
            onClick={() => updatePodcastList()}
          ></Button>
        </div>
      </div>
    </div>
  )
}

export default AllEpisodes
