import './RouteTransition.css'
import { AnimatePresence } from 'framer-motion'
import { useLocation, Route, Routes } from 'react-router-dom'
import HomePage from '../../pages/HomePage.jsx'
import PrivacyPolicy from '../../pages/PrivacyPolicy.jsx'
import AboutPage from '../../pages/AboutPage.jsx'
import CookiePolicy from '../../pages/CookiePolicy.jsx'
import EpisodePage from '../../pages/EpisodePage.jsx'
import BusinessPodcastPage from '../../pages/BusinessPodcastPage.jsx'
import DarkMatterUncoveredPage from '../../pages/DarkMatterUncoveredPage.jsx'
import NetZeroFuturePage from '../../pages/NetZeroFuturePage.jsx'
import { useEffect, useState } from 'react'
import HeaderSection from '../../sections/HeaderSection/HeaderSection.jsx'

const RouteTransition = ({ setEpisodeId }) => {
  let colorPrimary = 'var(--grey-home)'
  let colorSecondary = 'var(--light-grey-home)'

  const location = useLocation()
  const [pathName, setPathName] = useState('')

  useEffect(() => {
    const pathLocation = location.pathname.split('/')
    const currentPath = pathLocation[1]
    setPathName(currentPath)
  }, [location.pathname])

  if (pathName === '*' && 'about' && 'cookie-policy' && 'privacy-policy') {
    colorPrimary = 'var(--grey-home)'
    colorSecondary = 'var(--light-grey-home)'
  } else if (pathName === 'net-zero-future-podcast') {
    colorPrimary = 'var(--blue-net-zero)'
    colorSecondary = 'var(--lime-net-zero)'
  } else if (pathName === 'business-podcast') {
    colorPrimary = 'var(--violet-business)'
    colorSecondary = 'var(--green-business)'
  } else if (pathName === 'dark-matter-uncovered-podcast') {
    colorPrimary = 'var(--deep-blue-space)'
    colorSecondary = 'var(--lilac-space)'
  }

  const [currentColor, setCurrentColor] = useState(colorPrimary) // Change this to your default color
  const [previousColor, setPreviousColor] = useState(null)

  const handleColorChange = (colorPrimary) => {
    setPreviousColor(colorPrimary)
    setCurrentColor(colorPrimary)
  }
  useEffect(() => {
    handleColorChange(colorPrimary)
  }, [location.pathname])

  return (
    <>
      <HeaderSection colorPrimary={colorPrimary} pathName={pathName} />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            home
            path="/*"
            element={
              <HomePage
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
                pathName={pathName}
                setEpisodeId={setEpisodeId}
              />
            }
          />
          <Route
            path="/dark-matter-uncovered-podcast"
            element={
              <DarkMatterUncoveredPage
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
                pathName={pathName}
                setEpisodeId={setEpisodeId}
              />
            }
          />
          <Route
            path="/net-zero-future-podcast"
            element={
              <NetZeroFuturePage
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
                pathName={pathName}
                setEpisodeId={setEpisodeId}
              />
            }
          />
          <Route
            path="/business-podcast"
            element={
              <BusinessPodcastPage
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
                pathName={pathName}
                setEpisodeId={setEpisodeId}
              />
            }
          />
          <Route
            path="/about"
            element={
              <AboutPage
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
                pathName={pathName}
              />
            }
          />
          <Route
            path="/cookie-policy"
            element={
              <CookiePolicy
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
              />
            }
          />
          <Route
            path="/episode/:episode_id"
            element={
              <EpisodePage
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                previousColor={previousColor}
                setEpisodeId={setEpisodeId}
              />
            }
          />
        </Routes>
      </AnimatePresence>
    </>
  )
}

export default RouteTransition
