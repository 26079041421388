// TextArea.jsx
import React from 'react'
import './TextArea.css'

const TextArea = ({
  value,
  onChange,
  placeholder,
  className = '',
  isRequired,
  drakStyle,
  rows = '6',
}) => {
  const drakBg = drakStyle ? 'rgba(var(--black-code), 0.4)' : 'rgba(var(--white-code), 0.4)'
  return (
    <div className={`TextArea ${className}`}>
      {/* <label>{label}</label> */}
      <textarea
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        className="custom-textarea"
        {...(isRequired ? { required: true } : {})}
        style={{ backgroundColor: drakBg }}
      />
      {isRequired ? <span className="isRequired">*</span> : null}
    </div>
  )
}

export default TextArea
