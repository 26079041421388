const XIcon = ({ color = 'var(--white)', width = 28, className = '' }) => {
  const ratio = 30 / 28
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 28">
        <path
          fill="#fff"
          d="m.073 0 11.583 15.444L0 28h2.623l10.205-10.994L21.073 28H30L17.765 11.688 28.615 0H25.99l-9.398 10.124L9 0H.073Zm3.858 1.927h4.1l18.11 24.146h-4.1L3.93 1.927Z"
        />
      </svg>
    </div>
  )
}

export default XIcon
