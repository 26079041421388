import './App.css'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import Modal from './components/Modal/Modal.jsx'
// import Subscribe from './components/Subscribe/Subscribe.jsx'
// import MusicPlatformsSection from './sections/MusicPlatformsSection/MusicPlatformsSection.jsx'
import LuxUnpluggedLogo from './components/_SVG/LuxUnpluggedLogo.jsx'
import { useStore } from './store.js'

import CookiesPanel from './components/CookiesPanel/CookiesPanel.jsx'
import { bake_cookie, read_cookie, delete_cookie } from 'sfcookies'
import { useEffect, useState } from 'react'
import ScrollToTop from './components/ScrollToTop.jsx'
import Share from './components/Share/Share.jsx'
import RouteTransition from './components/Transition/RouteTransition.jsx'
import ReactGA from 'react-ga'

const TRACKING_ID = 'G-E7GFPM2GEH' //Google Analytics ID
ReactGA.initialize(TRACKING_ID)

function App() {
  const isCloseModal = useStore((state) => state.isCloseModal)
  const [modalClosed, setModalClosed] = useState(null)
  const [cookiesAllowed, setCookiesAllowed] = useState(null)
  const { isModalShare, toggleModalShare } = useStore()
  const [episodeId, setEpisodeId] = useState(null)

  const MODAL_TIMEOUT_MS = 5000

  const cookiesNames = {
    accepted: 'acceptall',
    modalClosed: 'modalclosed',
  }
  //delete_cookie(cookiesNames.accepted)
  //delete_cookie(cookiesNames.modalClosed)

  useEffect(() => {
    if (read_cookie(cookiesNames.accepted).length != 0) {
      setCookiesAllowed(true)
    } else {
      setCookiesAllowed(false)
    }
    if (read_cookie(cookiesNames.modalClosed).length != 0) {
      setModalClosed(true)
    } else {
      setTimeout(() => {
        setModalClosed(false)
      }, MODAL_TIMEOUT_MS)
    }
  }, [])

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />

        <RouteTransition setEpisodeId={setEpisodeId} />
        <CookiesPanel
          cookiesAllowed={cookiesAllowed}
          setCookiesAllowed={setCookiesAllowed}
          makeCookie={bake_cookie}
          cookiesNames={cookiesNames}
        />
      </BrowserRouter>
      {/* <Modal
        modalClosed={modalClosed}
        setModalClosed={setModalClosed}
        makeCookie={bake_cookie}
        cookiesNames={cookiesNames}
        cookiesAllowed={cookiesAllowed}
        className="subscribe-modal"
        isCloseModal={isCloseModal}
      >
        <LuxUnpluggedLogo className="Modal-logo mb-4" index={'Modal1'} />
        <Subscribe showSocial={false} />
        <MusicPlatformsSection Title="" />
      </Modal> */}
      <Modal
        className="share-modal"
        isModalNoCookies={isModalShare}
        toggleModaldNoCookies={toggleModalShare}
      >
        <LuxUnpluggedLogo className="Modal-logo mb-4" index={'Modal2'} />
        <Share
          isModalShare={isModalShare}
          toggleModaldNoCookies={toggleModalShare}
          episodeId={episodeId}
        />
      </Modal>
    </div>
  )
}

export default App
