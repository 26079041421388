const InstagramIcon = ({ color = 'var(--white)', width = 24, className = '' }) => {
  const ratio = 24 / 24
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill="#fff"
          d="M12.198 6.286a6.143 6.143 0 0 0-6.152 6.153 6.143 6.143 0 0 0 6.152 6.154 6.143 6.143 0 0 0 6.152-6.154 6.143 6.143 0 0 0-6.152-6.153Zm0 10.154c-2.2 0-4-1.794-4-4 0-2.207 1.794-4.001 4-4.001s4 1.794 4 4c0 2.207-1.8 4-4 4Zm7.838-10.406c0 .798-.642 1.436-1.434 1.436a1.435 1.435 0 1 1 1.435-1.435Zm4.075 1.457c-.091-1.923-.53-3.626-1.938-5.029C20.77 1.06 19.067.62 17.145.524c-1.98-.113-7.919-.113-9.9 0-1.916.09-3.619.53-5.027 1.933S.376 5.563.28 7.486c-.113 1.981-.113 7.92 0 9.902.09 1.922.53 3.625 1.938 5.028 1.408 1.403 3.105 1.843 5.027 1.939 1.981.112 7.92.112 9.9 0 1.922-.091 3.625-.53 5.028-1.939 1.403-1.403 1.842-3.106 1.938-5.028.112-1.982.112-7.916 0-9.897Zm-2.56 12.023a4.05 4.05 0 0 1-2.28 2.281c-1.58.627-5.328.482-7.073.482s-5.499.14-7.073-.482a4.05 4.05 0 0 1-2.28-2.281c-.627-1.58-.483-5.329-.483-7.075 0-1.745-.139-5.5.482-7.074a4.05 4.05 0 0 1 2.281-2.281c1.58-.627 5.328-.482 7.073-.482s5.499-.14 7.073.482a4.05 4.05 0 0 1 2.28 2.28c.627 1.58.483 5.33.483 7.075 0 1.746.144 5.5-.482 7.075Z"
        />
      </svg>
    </div>
  )
}

export default InstagramIcon
