import './CirclesPatternSmall.css'

const CirclesPatternSmall = ({
  colorPrimary = 'var(--violet-business)',
  colorSecondary = 'var(--green-business)',
  width = 360,
  className = '',
  opacity = 0.6,
  bg = false,
  rotate = '',
  index,
}) => {
  const ratio = 360 / 360
  const height = width / ratio
  return (
    <div
      className={`SVG CirclesPatternSmall ${className}`}
      style={{
        width: width,
        height: height,
        opacity: opacity,
        transform: `rotate(${rotate})`,
      }}
    >
      {bg === true ? <span style={{ backgroundColor: colorPrimary }}></span> : null}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 360 360">
        <g fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
          <path
            fill={`url(#b${index})`}
            d="M171.697 176.559a9.004 9.004 0 0 0 4.824 11.785 9.004 9.004 0 0 0 11.785-4.823 9.005 9.005 0 0 0-16.609-6.962Zm1.71 19.214c-8.689-3.642-12.781-13.639-9.138-22.328 3.642-8.689 13.638-12.78 22.328-9.138 8.689 3.642 12.78 13.639 9.138 22.328-3.642 8.689-13.639 12.78-22.328 9.138Z"
          />
          <path
            fill={`url(#c${index})`}
            d="M162.315 171.552c-4.688 9.767-.571 21.485 9.196 26.173 9.767 4.688 21.485.57 26.173-9.197 4.688-9.767.571-21.485-9.196-26.173-9.767-4.688-21.485-.57-26.173 9.197Zm5.711 33.434c-13.777-6.613-19.585-23.142-12.972-36.919 6.612-13.778 23.142-19.586 36.919-12.973 13.777 6.613 19.585 23.142 12.973 36.92-6.613 13.777-23.143 19.585-36.92 12.972Z"
            opacity=".938"
          />
          <path
            fill={`url(#d${index})`}
            d="M152.761 166.322c-7.576 15.043-1.523 33.38 13.521 40.956 15.043 7.577 33.38 1.524 40.956-13.52 7.577-15.043 1.524-33.38-13.52-40.957-15.043-7.576-33.38-1.523-40.957 13.521Zm9.898 48.15c-19.017-9.577-26.668-32.757-17.091-51.773 9.577-19.017 32.757-26.668 51.773-17.091 19.016 9.577 26.668 32.757 17.091 51.773-9.577 19.017-32.757 26.668-51.773 17.091Z"
            opacity=".875"
          />
          <path
            fill={`url(#e${index})`}
            d="M143.233 161.063c-10.481 20.308-2.515 45.267 17.793 55.748 20.308 10.481 45.267 2.514 55.748-17.794 10.481-20.308 2.514-45.267-17.794-55.748-20.308-10.481-45.267-2.514-55.747 17.794Zm14.099 62.905c-24.26-12.521-33.778-42.338-21.257-66.599 12.521-24.261 42.338-33.778 66.599-21.257 24.261 12.521 33.778 42.338 21.257 66.599-12.52 24.261-42.338 33.778-66.599 21.257Z"
            opacity=".813"
          />
          <path
            fill={`url(#f${index})`}
            d="M133.706 155.79c-13.393 25.568-3.523 57.151 22.044 70.544 25.567 13.393 57.151 3.523 70.544-22.044 13.392-25.568 3.523-57.151-22.045-70.544-25.567-13.393-57.15-3.523-70.543 22.044Zm18.307 77.679c-29.508-15.457-40.899-51.908-25.442-81.416 15.457-29.508 51.908-40.898 81.416-25.442 29.508 15.457 40.898 51.908 25.441 81.416-15.456 29.508-51.907 40.899-81.415 25.442Z"
            opacity=".75"
          />
          <path
            fill={`url(#g${index})`}
            d="M124.189 150.514c-16.307 30.825-4.538 69.032 26.287 85.339 30.825 16.307 69.032 4.538 85.339-26.287 16.307-30.824 4.538-69.032-26.287-85.339-30.824-16.307-69.032-4.538-85.339 26.287Zm22.521 92.459c-34.757-18.387-48.027-61.469-29.64-96.225 18.387-34.757 61.468-48.027 96.225-29.64 34.756 18.387 48.027 61.468 29.64 96.225-18.387 34.756-61.469 48.027-96.225 29.64Z"
            opacity=".688"
          />
          <path
            fill={`url(#h${index})`}
            d="M114.792 145.289c-19.193 36.014-5.556 80.768 30.458 99.96 36.014 19.193 80.768 5.556 99.96-30.458 19.193-36.014 5.556-80.768-30.458-99.96-36.014-19.193-80.768-5.556-99.96 30.458Zm26.67 107.068c-39.94-21.284-55.063-70.916-33.778-110.856 21.284-39.94 70.917-55.063 110.856-33.778 39.94 21.284 55.063 70.916 33.779 110.856-21.285 39.94-70.917 55.063-110.857 33.778Z"
            opacity=".625"
          />
          <path
            fill={`url(#i${index})`}
            d="M105.271 140.01c-22.108 41.271-6.573 92.65 34.698 114.758 41.271 22.108 92.65 6.573 114.758-34.698 22.107-41.271 6.573-92.65-34.698-114.758-41.271-22.108-92.65-6.573-114.758 34.698Zm30.895 121.858c-45.193-24.209-62.203-80.469-37.995-125.661 24.209-45.192 80.469-62.203 125.661-37.995 45.192 24.209 62.203 80.469 37.995 125.661-24.209 45.193-80.469 62.203-125.661 37.995Z"
            opacity=".563"
          />
          <path
            fill={`url(#j${index})`}
            d="M95.768 134.719c-25.03 46.521-7.609 104.524 38.912 129.555 46.521 25.03 104.525 7.608 129.555-38.913 25.03-46.521 7.609-104.525-38.912-129.555S120.798 88.198 95.768 134.72Zm35.096 136.648c-50.439-27.138-69.327-90.026-42.19-140.465 27.139-50.438 90.027-69.327 140.465-42.189 50.438 27.138 69.327 90.026 42.189 140.465-27.138 50.438-90.026 69.327-140.464 42.189Z"
            opacity=".5"
          />
          <path
            fill={`url(#k${index})`}
            d="M86.247 129.435c-27.948 51.778-8.63 116.409 43.148 144.357 51.778 27.949 116.409 8.631 144.357-43.147 27.948-51.778 8.631-116.409-43.147-144.357-51.778-27.949-116.409-8.631-144.358 43.147ZM125.57 280.88c-55.693-30.061-76.47-99.578-46.41-155.271 30.062-55.692 99.579-76.47 155.272-46.41 55.692 30.062 76.471 99.579 46.409 155.272-30.061 55.692-99.579 76.471-155.271 46.409Z"
            opacity=".438"
          />
          <path
            fill={`url(#l${index})`}
            d="M76.746 124.149c-30.868 57.027-9.661 128.281 47.367 159.149 57.027 30.868 128.281 9.661 159.149-47.367 30.867-57.028 9.661-128.281-47.367-159.149-57.028-30.868-128.281-9.661-159.15 47.367Zm43.533 166.232c-60.94-32.985-83.602-109.127-50.616-170.066 32.985-60.94 109.126-83.601 170.066-50.616s83.601 109.126 50.616 170.066c-32.986 60.94-109.127 83.601-170.066 50.616Z"
            opacity=".375"
          />
          <path
            fill={`url(#m${index})`}
            d="M67.225 118.854c-33.792 62.284-10.695 140.17 51.589 173.962 62.285 33.792 140.17 10.695 173.963-51.589 33.792-62.285 10.695-140.171-51.59-173.963-62.284-33.792-140.17-10.695-173.962 51.59Zm47.748 181.042c-66.195-35.914-90.742-118.689-54.828-184.884C96.059 48.818 178.834 24.27 245.028 60.184c66.195 35.914 90.742 118.689 54.829 184.884-35.914 66.194-118.689 90.742-184.884 54.828Z"
            opacity=".313"
          />
          <path
            fill={`url(#n${index})`}
            d="M81.584 81.624c-54.354 54.354-54.354 142.478 0 196.832 54.353 54.353 142.478 54.353 196.831 0 54.353-54.354 54.353-142.478 0-196.832-54.353-54.353-142.478-54.353-196.831 0Zm-5.696 202.527c-57.499-57.499-57.499-150.723 0-208.222 57.499-57.499 150.723-57.499 208.222 0 57.499 57.499 57.499 150.723 0 208.222-57.499 57.499-150.723 57.499-208.222 0Z"
            opacity=".25"
          />
          <path
            fill={`url(#o${index})`}
            d="M73.89 73.93c-58.603 58.603-58.603 153.617 0 212.22 58.603 58.603 153.617 58.603 212.22 0 58.603-58.603 58.603-153.617 0-212.22-58.603-58.603-153.617-58.603-212.22 0Zm-5.696 217.916c-61.748-61.749-61.748-161.863 0-223.612 61.749-61.748 161.863-61.748 223.611 0 61.749 61.749 61.749 161.863 0 223.612-61.748 61.748-161.862 61.748-223.61 0Z"
            opacity=".188"
          />
          <path
            fill={`url(#p${index})`}
            d="M66.197 66.235c-62.852 62.853-62.852 164.757 0 227.61 62.853 62.852 164.757 62.852 227.61 0 62.852-62.853 62.852-164.757 0-227.61-62.853-62.852-164.757-62.852-227.61 0ZM60.502 299.54c-65.998-65.998-65.998-173.002 0-239 65.998-65.998 173.002-65.998 239 0 65.998 65.998 65.998 173.002 0 239-65.998 65.998-173.002 65.998-239 0Z"
            opacity=".125"
          />
          <path
            fill={`url(#q${index})`}
            d="M180.001 8.213C85.103 8.213 8.174 85.143 8.174 180.04c0 94.898 76.93 171.827 171.827 171.827 94.898 0 171.827-76.929 171.827-171.827S274.899 8.213 180.001 8.213ZM.119 180.04C.12 80.694 80.655.158 180.001.158S359.883 80.694 359.883 180.04s-80.536 179.882-179.882 179.882S.119 279.386.119 180.04Z"
            opacity=".063"
          />
        </g>
        <defs>
          <linearGradient
            id={`b${index}`}
            x1="185.734"
            x2="174.348"
            y1="192.262"
            y2="167.785"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`c${index}`}
            x1="188.283"
            x2="171.845"
            y1="200.31"
            y2="159.725"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`d${index}`}
            x1="191.001"
            x2="169.18"
            y1="208.496"
            y2="151.524"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`e${index}`}
            x1="193.748"
            x2="166.492"
            y1="216.665"
            y2="143.34"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`f${index}`}
            x1="196.498"
            x2="163.786"
            y1="224.828"
            y2="135.162"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`g${index}`}
            x1="199.261"
            x2="161.08"
            y1="232.987"
            y2="126.989"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`h${index}`}
            x1="201.982"
            x2="158.407"
            y1="241.048"
            y2="118.913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`i${index}`}
            x1="204.751"
            x2="155.686"
            y1="249.204"
            y2="110.742"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`j${index}`}
            x1="207.512"
            x2="152.982"
            y1="257.36"
            y2="102.572"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`k${index}`}
            x1="210.283"
            x2="150.259"
            y1="265.517"
            y2="94.4"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`l${index}`}
            x1="213.052"
            x2="147.549"
            y1="273.667"
            y2="86.235"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`m${index}`}
            x1="215.818"
            x2="144.829"
            y1="281.83"
            y2="78.057"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`n${index}`}
            x1="185.829"
            x2="174.898"
            y1="296.408"
            y2="63.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`o${index}`}
            x1="186.26"
            x2="174.521"
            y1="305.009"
            y2="55.07"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`p${index}`}
            x1="186.693"
            x2="174.146"
            y1="313.609"
            y2="46.469"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id={`q${index}`}
            x1="84.507"
            x2="276.125"
            y1="285.606"
            y2="75.102"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} />
            <stop offset=".25" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorSecondary} stopOpacity="0" />
          </linearGradient>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h360v360H0z" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default CirclesPatternSmall
