import './Button.css'
import { Link } from 'react-router-dom'

const Button = ({
  color = 'var(--red-home)',
  className = '',
  sm,
  text = '',
  icon = true,
  type = 'button',
  noText = '',
  children,
  secondary = false,
  fixedWidth = '',
  ariaLabel = 'Button',
  onClick,
  linkButton = false,
  link = '',
}) => {
  return (
    <>
      {linkButton === false ? (
        <button
          onClick={onClick}
          type={type}
          aria-label={ariaLabel}
          className={`Button ${secondary === true ? 'secondary' : null} ${className} ${noText} ${
            sm ? 'small' : null
          }`}
          style={{
            backgroundColor: secondary === true ? 'transparent' : color,
            borderColor: secondary === true ? color : null,
            width: fixedWidth,
          }}
        >
          {icon === true ? <i>{children}</i> : null}

          <span
            style={{
              color: secondary === true ? color : 'var(--white)',
              borderColor: secondary === true ? color : null,
            }}
          >
            {text}
          </span>
        </button>
      ) : null}
      {linkButton === true ? (
        <div
          onClick={onClick}
          type={type}
          aria-label={ariaLabel}
          className={`Button ${secondary === true ? 'secondary' : null} ${className} ${noText} ${
            sm ? 'small' : null
          }`}
          style={{
            backgroundColor: secondary === true ? 'transparent' : color,
            borderColor: secondary === true ? color : null,
            width: fixedWidth,
          }}
        >
          <Link target="_self" to={link} aria-label={ariaLabel}>
            {icon === true ? <i>{children}</i> : null}
          </Link>
          <span
            style={{
              color: secondary === true ? color : 'var(--white)',
              borderColor: secondary === true ? color : null,
            }}
          >
            {text}
          </span>
        </div>
      ) : null}
    </>
  )
}

export default Button
