const AppleIcon = ({ color = 'var(--white)', width = 80, className = '' }) => {
  const ratio = 80 / 80
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <a
        aria-label="Go to Apple Podcast"
        href="https://podcasts.apple.com/lu/podcast/luxunplugged-podcast/id1484198880"
        target="_blanc"
      >
        <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" fill="none">
          <path
            fill={color}
            d="M78.384 69.322a16.895 16.895 0 0 1-10.747 9.633 35.076 35.076 0 0 1-9.66 1.024H22.023a34.473 34.473 0 0 1-9.66-1.024 16.895 16.895 0 0 1-10.747-9.633A31.402 31.402 0 0 1 .032 57.977v-35.97c-.176-3.84.368-7.68 1.584-11.33a16.933 16.933 0 0 1 10.747-9.632 35.073 35.073 0 0 1 9.66-1.024h35.954a34.47 34.47 0 0 1 9.66 1.024 16.895 16.895 0 0 1 10.747 9.633 31.316 31.316 0 0 1 1.584 11.329v35.97c.16 3.84-.368 7.697-1.584 11.345ZM45.79 45.992a7.979 7.979 0 0 0-5.79-2.08 7.955 7.955 0 0 0-5.79 2.08 3.714 3.714 0 0 0-1.055 2.305 30.572 30.572 0 0 0 .128 6.048c.208 2.448.608 5.712 1.103 9.04.224 1.537.544 3.057.96 4.561a4.55 4.55 0 0 0 4.67 2.784 4.53 4.53 0 0 0 4.686-2.784 34.45 34.45 0 0 0 .944-4.56c.511-3.329.911-6.593 1.103-9.04.24-2.001.288-4.033.128-6.05a3.674 3.674 0 0 0-1.087-2.303ZM33.475 34.728a6.54 6.54 0 0 0 6.541 6.544 6.54 6.54 0 0 0 6.541-6.544 6.54 6.54 0 0 0-6.541-6.545 6.54 6.54 0 0 0-6.541 6.545Zm6.461-25.842c-15.514.032-28.069 12.657-28.037 28.178.033 11.92 7.565 22.53 18.809 26.466.192.08.4-.016.48-.208a.304.304 0 0 0 .016-.208 95.562 95.562 0 0 1-.4-2.88.985.985 0 0 0-.576-.769c-12.41-5.408-18.105-19.841-12.699-32.258C22.935 14.79 37.361 9.094 49.772 14.502c12.41 5.408 18.105 19.842 12.699 32.258a24.506 24.506 0 0 1-12.699 12.706 1.02 1.02 0 0 0-.576.767c-.128.96-.256 1.92-.4 2.88-.032.209.096.4.304.433a.46.46 0 0 0 .208-.016c14.65-5.12 22.375-21.17 17.257-35.827A28.105 28.105 0 0 0 39.936 8.886Zm-.512 12.913c8.397-.336 15.482 6.192 15.818 14.609a15.236 15.236 0 0 1-4.798 11.729c-.256.24-.384.576-.368.912a26.965 26.965 0 0 1-.032 3.216.375.375 0 0 0 .336.4c.08 0 .16-.016.24-.064 8.588-5.856 10.811-17.57 4.958-26.162-5.854-8.593-17.561-10.817-26.15-4.96-8.588 5.856-10.795 17.569-4.942 26.162a18.75 18.75 0 0 0 4.958 4.96c.176.112.4.064.512-.096a.368.368 0 0 0 .064-.224 26.965 26.965 0 0 1-.032-3.216 1.152 1.152 0 0 0-.368-.912c-6.141-5.745-6.445-15.394-.704-21.522a15.157 15.157 0 0 1 10.508-4.832Z"
          />
        </svg>
      </a>
    </div>
  )
}

export default AppleIcon
