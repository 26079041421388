import './EpisodeCard.css'
import CirclesPatternSmall from '../../components/_SVG/CirclesPatternSmall'
import TriangularGroup from '../_SVG/TriangularGroup'
import PlayButton from '../PlayButton/PlayButton'
import LuxUnpluggedLogo from '../_SVG/LuxUnpluggedLogo'
import Tag from '../Tag/Tag'

const EpisodeCard = ({
  colorPrimary = 'var(--violet-business)',
  colorSecondary = 'var(--green-business)',
  index = '01',
  className = '',
  imageSrc = '/img/cover/Business/Georges_Krombach.jpg',
  imageAlt = 'Georges Krombach',
  title = "How to preserve Luxembourg's competitive edge",
  name = 'Georges<br />Krombach',
  episodeNumber = '01',
  noCoverImage = false,
  insidePlayer,
}) => {
  return (
    <div
      key={index}
      className={`EpisodeCard ${className} ${insidePlayer === true ? 'insidePlayer' : null} ${
        noCoverImage === true ? 'noCoverImage' : null
      }`}
    >
      <div className="cover-image p-4" style={{ backgroundColor: colorPrimary }}>
        {noCoverImage === true ? null : (
          <img height={'600px'} width={'600px'} src={imageSrc} alt={imageAlt} />
        )}
        <div className="overlay" style={{ backgroundColor: colorPrimary }}></div>
        <Tag colorPrimary={colorPrimary} />
        <span className="episodeNumber">Ep. {episodeNumber}</span>
        <span className="Name" dangerouslySetInnerHTML={{ __html: name }}></span>
        <CirclesPatternSmall
          className={'bg-pattern'}
          width={noCoverImage === true ? 780 : 400}
          rotate={'-10deg'}
          index={index}
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
          opacity={0.7}
          bg={true}
        />
        <TriangularGroup
          className={'EpisodeCardTriangularGroup'}
          colorSecondary={colorSecondary}
          TriangularWidthOne={20}
          TriangularWidthTwo={10}
          TriangularWidthThree={12}
          colorThree={colorSecondary}
        />
        <LuxUnpluggedLogo
          className="card-logo m-4"
          width={noCoverImage === true ? 110 : 80}
          oneColor={true}
        />
      </div>

      <div className="title-listen p-4">
        <p className="title">{title}</p>
        <div className="listen">
          <PlayButton width={32} colorPrimary={'var(--black)'} colorSecondary={'var(--black)'} />
          <span className="ms-2">Listen</span>
        </div>
        <div className="hover-state" style={{ backgroundColor: colorPrimary }}></div>
      </div>
    </div>
  )
}

export default EpisodeCard
