import { create } from 'zustand'

export const useStore = create((set) => ({
  isCloseModal: true,
  toggleModal: () => set((state) => ({ isCloseModal: !state.isCloseModal })),
  isModalShare: true,
  toggleModalShare: () => set((state) => ({ isModalShare: !state.isModalShare })),
  isShowMenuMobile: false,
  toggleShowMenuMobile: () => set((state) => ({ isShowMenuMobile: !state.isShowMenuMobile })),
}))
