import React from 'react'
import './DescriptionSection.css'
import H3Title from '../../components/H3Tittle/H3Title'
import Button from '../../components/Button/Button'
import { Link } from 'react-router-dom'

const DescriptionSection = ({
  colorSecondary = '',
  isHomeColor,
  pathName,
  id,
  episodeNumber,
  descriptionText = null,
}) => {
  let description = ''
  if (descriptionText != null) {
    description = descriptionText.replaceAll('<div>', '')
    description = description.replaceAll('</div>', '')
    let words = description.split(' ')
    if (words.length > 50) {
      words.length = 50
      description = words.join(' ') + '...'
    }
  }
  const colorPrimaryByCategory = () => {
    if (pathName === 'business-podcast') {
      return 'rgba(var(--violet-business-code), 0.2)'
    } else if (pathName === 'net-zero-future-podcast') {
      return 'rgba(var(--blue-net-zero-code), 0.2)'
    } else if (pathName === 'dark-matter-uncovered-podcast') {
      return 'rgba(var(--deep-blue-space-code), 0.2)'
    } else {
      return 'rgba(var(--black-code), 0.1)'
    }
  }
  //console.log('episodeNumber', episodeNumber, id)
  let url = '/episode/' + id
  return (
    <section>
      <div className="container DescriptionSection position-relative">
        <div className="row">
          <div className="col-lg-3 col-sm-12">
            <H3Title isHomeColor={isHomeColor} colorSecondary={colorSecondary} />
          </div>
          <div className="col-lg-9 col-sm-12">
            <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
            <Link to={url}>
              <Button
                ariaLabel={'Read More'}
                className=""
                secondary
                color="var(--black)"
                text={'Read More'}
                fixedWidth={'200px'}
                linkButton={true}
                link={'/episode/' + id}
              ></Button>
            </Link>
          </div>
        </div>
        <div className="description-section-number" style={{ color: colorPrimaryByCategory() }}>
          {episodeNumber}
        </div>
      </div>
    </section>
  )
}

export default DescriptionSection
