import React, { useState } from 'react'
import H3Title from '../../components/H3Tittle/H3Title'
import './FooterSection.css'
import InputField from '../../components/InputField/InputField'
import Button from '../../components/Button/Button'
import TextFiled from '../../components/TextArea/TextArea'
import Subscribe from '../../components/Subscribe/Subscribe'
import { Link } from 'react-router-dom'
import Section from '../_Section/Section'
import { is_email } from '../../cross-page-functions'
import axios from 'axios'

const LUXUNPLUGGED_API = import.meta.env.VITE_PATH_TO_API

const FooterSection = ({ className = '' }) => {
  const [NameInputValue, setNameInputValue] = useState('')
  const [EmailInputValue, setEmailInputValue] = useState('')
  const [MessageInputValue, setMessageInputValue] = useState('')

  const handleNameInputChange = (e) => {
    setNameInputValue(e.target.value)
  }
  const handleEmailInputChange = (e) => {
    setEmailInputValue(e.target.value)
  }
  const handleMessageInputChange = (e) => {
    setMessageInputValue(e.target.value)
  }

  function sendContactUsForm() {
    if (is_email(EmailInputValue) == false) {
      alert('Email is not valid')
      return
    }
    if (NameInputValue.length < 2) {
      alert('Name is too short')
      return
    }
    if (MessageInputValue.length < 15) {
      alert('Message has to be at least 15 charachters long')
      return
    }

    axios
      .post(LUXUNPLUGGED_API + '/send-contact-us-form', {
        email: EmailInputValue,
        name: NameInputValue,
        message: MessageInputValue,
      })
      .then((response) => {
        if (response.status == 200) {
          alert('Your request was submitted successfully')
          setEmailInputValue('')
          setMessageInputValue('')
          setNameInputValue('')
        } else {
          alert('Error occured when submitting the form')
        }
      })
  }
  return (
    <Section
      className={'FooterSection position-relative'}
      color="var(--violet-business)"
      isFooter
      overflowType="hidden"
    >
      <div className={`${className} container position-relative mb-5`}>
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <H3Title
              isHomeColor={true}
              colorTwo="var(--white)"
              textColor="var(--white)"
              text="Contact Us"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 offset-md-2  col-xl-6 offset-xl-3">
            <div className="input-field-group mb-3">
              <InputField
                className="my-3"
                value={NameInputValue}
                onChange={handleNameInputChange}
                placeholder="Name"
                drakStyle
                isRequired
              />
              <InputField
                className="my-3"
                value={EmailInputValue}
                onChange={handleEmailInputChange}
                placeholder="Email"
                drakStyle
                isRequired
              />
              <TextFiled
                className="my-3"
                value={MessageInputValue}
                onChange={handleMessageInputChange}
                placeholder="Message"
                drakStyle
                isRequired
              />
              <Button
                secondary
                ariaLabel={'Send Message'}
                color="var(--white)"
                text={'Send'}
                fixedWidth={'200px'}
                onClick={() => sendContactUsForm()}
              ></Button>
            </div>
            {/* <Subscribe /> */}
          </div>
        </div>
      </div>
      <div className="secondary-footer-nav">
        <ul className="d-flex p-0">
          <li className="m-2">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li className="m-2">
            <Link to="/cookie-policy">Cookie Policy</Link>
          </li>
        </ul>
      </div>
      <div className="footer-overlay"></div>
      <img
        className="footer-bg"
        src="/img/footer_bg.jpg"
        alt="background"
        width="1920"
        height="1080"
      />
      <div className="copyright px-4 text-center">
        ©{new Date().getFullYear()} Luxembourg Business Vision Ltd. Company Registered in the UK
        13660945
      </div>
    </Section>
  )
}

export default FooterSection
