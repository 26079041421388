import './CirclesPatternBig.css'

const CirclesPatternBig = ({
  colorPrimary = 'var(--blue-net-zero)',
  colorSecondary = 'var(--lime-net-zero)',
  width = 1900,
  className = '',
  opacity = 1,
  isHome = false,
}) => {
  const ratio = 1997 / 1997
  const height = width / ratio
  return (
    <div
      className={`SVG CirclesPatternBig ${className}`}
      style={{ width: width, height: height, opacity: opacity }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1997 1997">
        <path
          fill="url(#b)"
          d="M1000.59 1000.62a3.603 3.603 0 0 1-5.1-.03c-1.4-1.42-1.39-3.7.03-5.1 1.42-1.4 3.7-1.39 5.1.03 1.4 1.42 1.39 3.7-.03 5.1Zm-17.73-17.94c-8.49 8.39-8.57 22.08-.18 30.57 8.39 8.49 22.08 8.57 30.57.18 8.49-8.39 8.57-22.08.18-30.57-8.39-8.49-22.08-8.57-30.57-.18Z"
          opacity=".7"
        />
        <path
          fill="url(#c)"
          d="M1017.35 1017.57c-10.78 10.65-28.15 10.55-38.81-.23-10.66-10.78-10.55-28.15.23-38.81 10.78-10.65 28.15-10.55 38.8.23 10.65 10.78 10.55 28.15-.23 38.8l.01.01Zm-51.23-51.83c-17.84 17.64-18.01 46.4-.37 64.25 17.64 17.84 46.4 18.01 64.25.37s18.01-46.4.37-64.24c-17.64-17.84-46.4-18.01-64.24-.37l-.01-.01Z"
          opacity=".7"
        />
        <path
          fill="url(#d)"
          d="M1034.1 1034.52c-20.14 19.91-52.599 19.72-72.509-.42-19.9-20.14-19.72-52.6.42-72.51 20.14-19.9 52.599-19.71 72.509.42 19.9 20.14 19.72 52.6-.42 72.51Zm-84.719-85.71c-27.2 26.88-27.45 70.73-.57 97.93 26.88 27.2 70.729 27.45 97.929.57 27.2-26.88 27.45-70.73.57-97.92-26.88-27.19-70.729-27.45-97.919-.57l-.01-.01Z"
          opacity=".7"
        />
        <path
          fill="url(#e)"
          d="M1050.85 1051.47c-29.5 29.16-77.05 28.88-106.21-.62-29.16-29.5-28.88-77.05.62-106.21 29.5-29.16 77.05-28.88 106.21.62 29.16 29.5 28.88 77.05-.62 106.21Zm-118.21-119.6c-36.55 36.13-36.9 95.05-.77 131.61 36.13 36.55 95.05 36.9 131.61.77 36.55-36.13 36.9-95.05.77-131.6-36.13-36.55-95.05-36.9-131.6-.77l-.01-.01Z"
          opacity=".7"
        />
        <path
          fill="url(#f)"
          d="M998.06 1096.99c-54.64 0-98.93-44.3-98.93-98.93 0-54.63 44.29-98.93 98.93-98.93 54.64 0 98.93 44.3 98.93 98.93 0 54.63-44.29 98.93-98.93 98.93Zm0-215.81c-64.55 0-116.88 52.33-116.88 116.88s52.33 116.88 116.88 116.88 116.88-52.33 116.88-116.88-52.33-116.88-116.88-116.88Z"
          opacity=".2"
        />
        <path
          fill="url(#g)"
          d="M1084.36 1085.37c-48.22 47.66-125.95 47.21-173.61-1.01-47.66-48.22-47.21-125.95 1.01-173.61 48.22-47.66 125.95-47.2 173.61 1.02 47.66 48.22 47.21 125.95-1.01 173.61v-.01ZM899.16 898c-55.26 54.62-55.78 143.7-1.16 198.97 54.62 55.26 143.7 55.78 198.97 1.16 55.26-54.62 55.78-143.7 1.16-198.96-54.62-55.26-143.7-55.78-198.96-1.16l-.01-.01Z"
          opacity=".7"
        />
        {isHome === true ? (
          <path
            fill="url(#gc)"
            d="M1084.36 1085.37c-48.22 47.66-125.95 47.21-173.61-1.01-47.66-48.22-47.21-125.95 1.01-173.61 48.22-47.66 125.95-47.2 173.61 1.02 47.66 48.22 47.21 125.95-1.01 173.61v-.01ZM899.16 898c-55.26 54.62-55.78 143.7-1.16 198.97 54.62 55.26 143.7 55.78 198.97 1.16 55.26-54.62 55.78-143.7 1.16-198.96-54.62-55.26-143.7-55.78-198.96-1.16l-.01-.01Z"
            opacity="1"
          />
        ) : null}
        <path
          fill="url(#h)"
          d="M1101.11 1102.32c-57.58 56.91-150.4 56.37-207.31-1.21s-56.37-150.4 1.21-207.31 150.4-56.37 207.31 1.21 56.37 150.4-1.21 207.31Zm-218.7-221.26c-64.62 63.87-65.23 168.03-1.36 232.65 63.87 64.62 168.03 65.22 232.65 1.36 64.62-63.87 65.22-168.03 1.36-232.64-63.87-64.62-168.03-65.22-232.64-1.36l-.01-.01Z"
          opacity=".7"
        />
        {isHome === true ? (
          <path
            fill="url(#hc)"
            d="M1101.11 1102.32c-57.58 56.91-150.4 56.37-207.31-1.21s-56.37-150.4 1.21-207.31 150.4-56.37 207.31 1.21 56.37 150.4-1.21 207.31Zm-218.7-221.26c-64.62 63.87-65.23 168.03-1.36 232.65 63.87 64.62 168.03 65.22 232.65 1.36 64.62-63.87 65.22-168.03 1.36-232.64-63.87-64.62-168.03-65.22-232.64-1.36l-.01-.01Z"
            opacity="1"
          />
        ) : null}
        <path
          fill="url(#i)"
          d="M1117.86 1119.26c-66.94 66.17-174.851 65.53-241.021-1.41-66.16-66.94-65.54-174.85 1.41-241.02 66.94-66.16 174.851-65.53 241.011 1.41s65.54 174.85-1.4 241.01v.01ZM865.669 864.11c-73.97 73.12-74.67 192.35-1.55 266.33 73.11 73.97 192.351 74.67 266.331 1.55 73.97-73.11 74.67-192.35 1.55-266.32-73.11-73.97-192.351-74.67-266.321-1.55l-.01-.01Z"
          opacity=".7"
        />
        {isHome === true ? (
          <path
            fill="url(#ic)"
            d="M1117.86 1119.26c-66.94 66.17-174.851 65.53-241.021-1.41-66.16-66.94-65.54-174.85 1.41-241.02 66.94-66.16 174.851-65.53 241.011 1.41s65.54 174.85-1.4 241.01v.01ZM865.669 864.11c-73.97 73.12-74.67 192.35-1.55 266.33 73.11 73.97 192.351 74.67 266.331 1.55 73.97-73.11 74.67-192.35 1.55-266.32-73.11-73.97-192.351-74.67-266.321-1.55l-.01-.01Z"
            opacity=".7"
          />
        ) : null}
        <path
          fill="url(#j)"
          d="M1134.62 1136.21c-76.3 75.42-199.3 74.7-274.72-1.6-75.41-76.3-74.7-199.3 1.6-274.72 76.3-75.41 199.3-74.69 274.71 1.61 75.41 76.3 74.7 199.3-1.6 274.71h.01ZM848.93 847.18c-83.33 82.36-84.11 216.68-1.75 300.01 82.36 83.33 216.68 84.11 300.01 1.75 83.33-82.36 84.11-216.68 1.75-300-82.36-83.33-216.68-84.11-300-1.75l-.01-.01Z"
          opacity=".7"
        />
        <path
          fill="url(#k)"
          d="M1151.37 1153.16c-85.66 84.67-223.75 83.86-308.42-1.8-84.67-85.66-83.87-223.75 1.8-308.42 85.66-84.67 223.75-83.86 308.41 1.8 84.66 85.66 83.87 223.75-1.79 308.41v.01ZM832.19 830.24c-92.68 91.61-93.55 241-1.95 333.69 91.61 92.68 241 93.55 333.69 1.94 92.68-91.61 93.55-241 1.94-333.68s-241-93.55-333.68-1.95Z"
          opacity=".7"
        />
        <path
          fill="url(#l)"
          d="M894.231 1216.56c-120.68-57.35-172.01-201.67-114.67-322.34 57.34-120.67 201.66-172.02 322.339-114.67 120.68 57.35 172.01 201.66 114.66 322.33-57.34 120.67-201.66 172.02-322.329 114.67v.01Zm215.319-453.13c-129.579-61.58-284.549-6.45-346.119 123.13-61.58 129.58-6.45 284.54 123.13 346.12 129.579 61.58 284.539 6.45 346.119-123.13 61.58-129.58 6.45-284.54-123.13-346.12Z"
          opacity=".85"
        />
        <path
          fill="url(#m)"
          d="M884.001 1238.09c-132.57-62.99-188.96-221.53-125.97-354.09 62.99-132.56 221.52-188.96 354.089-125.97 132.56 62.99 188.95 221.53 125.96 354.09-62.99 132.56-221.52 188.96-354.079 125.97Zm235.769-496.17c-141.459-67.22-310.629-7.04-377.849 134.42-67.22 141.46-7.04 310.63 134.42 377.85 141.459 67.22 310.629 7.04 377.849-134.42 67.22-141.46 7.04-310.63-134.42-377.85Z"
          opacity=".9"
        />
        {isHome === true ? (
          <path
            fill="url(#mc)"
            d="M884.001 1238.09c-132.57-62.99-188.96-221.53-125.97-354.09 62.99-132.56 221.52-188.96 354.089-125.97 132.56 62.99 188.95 221.53 125.96 354.09-62.99 132.56-221.52 188.96-354.079 125.97Zm235.769-496.17c-141.459-67.22-310.629-7.04-377.849 134.42-67.22 141.46-7.04 310.63 134.42 377.85 141.459 67.22 310.629 7.04 377.849-134.42 67.22-141.46 7.04-310.63-134.42-377.85Z"
            opacity=".9"
          />
        ) : null}
        <path
          fill="url(#n)"
          d="M873.769 1259.61c-144.45-68.64-205.9-241.4-137.26-385.84 68.64-144.44 241.39-205.91 385.841-137.26 144.44 68.64 205.89 241.39 137.25 385.84-68.64 144.44-241.39 205.91-385.831 137.27v-.01Zm256.221-539.2c-153.341-72.87-336.721-7.63-409.591 145.71s-7.63 336.72 145.71 409.59c153.341 72.87 336.721 7.63 409.581-145.71 72.87-153.34 7.63-336.72-145.71-409.58l.01-.01Z"
          opacity=".85"
        />
        <path
          fill="url(#o)"
          d="M1111.91 706.05c161.27 62.88 241.02 244.6 178.14 405.86-62.88 161.26-244.59 241.03-405.861 178.14-161.26-62.88-241.01-244.59-178.13-405.85 62.88-161.26 244.59-241.03 405.851-178.15Zm-234.181 600.59c170.431 66.45 362.461-17.83 428.911-188.26 66.45-170.43-17.83-362.46-188.27-428.91-170.431-66.45-362.461 17.84-428.911 188.26-66.45 170.43 17.83 362.46 188.26 428.91h.01Z"
          opacity=".95"
        />
        {isHome === true ? (
          <path
            fill="url(#oc)"
            d="M1111.91 706.05c161.27 62.88 241.02 244.6 178.14 405.86-62.88 161.26-244.59 241.03-405.861 178.14-161.26-62.88-241.01-244.59-178.13-405.85 62.88-161.26 244.59-241.03 405.851-178.15Zm-234.181 600.59c170.431 66.45 362.461-17.83 428.911-188.26 66.45-170.43-17.83-362.46-188.27-428.91-170.431-66.45-362.461 17.84-428.911 188.26-66.45 170.43 17.83 362.46 188.26 428.91h.01Z"
            opacity=".95"
          />
        ) : null}
        <path
          fill="url(#p)"
          d="M900.851 1320.99c-178.35-53.69-279.41-241.8-225.72-420.14 53.68-178.34 241.79-279.41 420.139-225.72 178.34 53.68 279.39 241.8 225.71 420.14-53.68 178.34-241.79 279.41-420.129 225.72Zm199.539-662.9c-187.759-56.52-385.779 49.87-442.299 237.63-56.52 187.76 49.87 385.78 237.63 442.3 187.759 56.52 385.779-49.87 442.299-237.63 56.52-187.76-49.87-385.78-237.63-442.3Z"
        />
        {isHome === true ? (
          <path
            fill="url(#pc)"
            d="M900.851 1320.99c-178.35-53.69-279.41-241.8-225.72-420.14 53.68-178.34 241.79-279.41 420.139-225.72 178.34 53.68 279.39 241.8 225.71 420.14-53.68 178.34-241.79 279.41-420.129 225.72Zm199.539-662.9c-187.759-56.52-385.779 49.87-442.299 237.63-56.52 187.76 49.87 385.78 237.63 442.3 187.759 56.52 385.779-49.87 442.299-237.63 56.52-187.76-49.87-385.78-237.63-442.3Z"
            opacity="1"
          />
        ) : null}
        {isHome === false ? (
          <path
            id="id-q"
            fill="url(#q)"
            d="M1120.22 1337.84c-187.66 67.47-394.48-29.98-461.94-217.63-67.46-187.65 29.97-394.47 217.63-461.94 187.65-67.46 394.47 29.99 461.93 217.63 67.46 187.65-29.97 394.47-217.62 461.94ZM869.89 641.55c-196.9 70.79-299.13 287.78-228.34 484.68 70.78 196.89 287.78 299.13 484.68 228.34 196.89-70.78 299.12-287.78 228.34-484.68-70.79-196.9-287.78-299.13-484.68-228.34Z"
            opacity=".69"
          />
        ) : null}
        {isHome === true ? (
          <path
            id="id-qc"
            fill="url(#qc)"
            d="M1120.22 1337.84c-187.66 67.47-394.48-29.98-461.94-217.63-67.46-187.65 29.97-394.47 217.63-461.94 187.65-67.46 394.47 29.99 461.93 217.63 67.46 187.65-29.97 394.47-217.62 461.94ZM869.89 641.55c-196.9 70.79-299.13 287.78-228.34 484.68 70.78 196.89 287.78 299.13 484.68 228.34 196.89-70.78 299.12-287.78 228.34-484.68-70.79-196.9-287.78-299.13-484.68-228.34Z"
            opacity="1"
          />
        ) : null}
        <path
          fill="url(#r)"
          d="M1107.62 1367.04c-203.79 60.5-418.04-55.66-478.54-259.43s55.65-418.03 259.43-478.54c203.78-60.5 418.03 55.66 478.53 259.44 60.5 203.77-55.65 418.03-259.42 478.53ZM883.46 612.05c-213.19 63.3-334.7 287.43-271.41 500.62 63.29 213.19 287.43 334.7 500.62 271.4 213.19-63.29 334.7-287.43 271.4-500.62-63.3-213.19-287.43-334.7-500.61-271.4Z"
          opacity=".67"
        />
        {isHome === true ? (
          <path
            fill="url(#rc)"
            d="M1107.62 1367.04c-203.79 60.5-418.04-55.66-478.54-259.43s55.65-418.03 259.43-478.54c203.78-60.5 418.03 55.66 478.53 259.44 60.5 203.77-55.65 418.03-259.42 478.53ZM883.46 612.05c-213.19 63.3-334.7 287.43-271.41 500.62 63.29 213.19 287.43 334.7 500.62 271.4 213.19-63.29 334.7-287.43 271.4-500.62-63.3-213.19-287.43-334.7-500.61-271.4Z"
            opacity="1"
          />
        ) : null}
        <path
          fill="url(#s)"
          d="M998.06 1406.79c-225.74 0-408.74-183.01-408.74-408.74 0-225.73 183-408.74 408.74-408.74s408.72 183.01 408.72 408.74c0 225.73-183 408.74-408.72 408.74Zm0-835.22c-235.54 0-426.48 190.94-426.48 426.48 0 235.54 190.94 426.48 426.48 426.48 235.54 0 426.48-190.94 426.48-426.48 0-235.54-190.94-426.48-426.48-426.48Z"
          opacity=".65"
        />
        {isHome === true ? (
          <path
            fill="url(#sc)"
            d="M998.06 1406.79c-225.74 0-408.74-183.01-408.74-408.74 0-225.73 183-408.74 408.74-408.74s408.72 183.01 408.72 408.74c0 225.73-183 408.74-408.72 408.74Zm0-835.22c-235.54 0-426.48 190.94-426.48 426.48 0 235.54 190.94 426.48 426.48 426.48 235.54 0 426.48-190.94 426.48-426.48 0-235.54-190.94-426.48-426.48-426.48Z"
            opacity="1"
          />
        ) : null}
        <path
          fill="url(#t)"
          d="M998.06 1430.62c-238.9 0-432.57-193.68-432.57-432.57s193.67-432.57 432.57-432.57 432.55 193.68 432.55 432.57-193.67 432.57-432.55 432.57Zm0-882.86c-248.69 0-450.3 201.6-450.3 450.3 0 248.7 201.6 450.3 450.3 450.3 248.7 0 450.29-201.61 450.29-450.3s-201.6-450.3-450.29-450.3Z"
          opacity=".63"
        />
        <path
          fill="url(#u)"
          d="M998.071 1454.46c-252.06 0-456.4-204.35-456.4-456.4 0-252.05 204.33-456.4 456.4-456.4 252.069 0 456.379 204.35 456.379 456.4 0 252.05-204.33 456.4-456.379 456.4Zm0-930.51c-261.85 0-474.11 212.27-474.11 474.11 0 261.84 212.27 474.11 474.11 474.11 261.839 0 474.109-212.27 474.109-474.11 0-261.84-212.27-474.11-474.109-474.11Z"
          opacity=".61"
        />
        <path
          fill="url(#v)"
          d="M1142.69 1455.99c-252.91 79.87-522.689-60.41-602.559-313.31s60.4-522.69 313.31-602.56c252.899-79.87 522.669 60.42 602.539 313.32 79.87 252.9-60.4 522.69-313.3 602.55h.01ZM848.111 523.25c-262.231 82.82-407.68 362.53-324.86 624.76s362.53 407.67 624.769 324.86c262.23-82.82 407.67-362.53 324.86-624.76-82.82-262.23-362.53-407.67-624.759-324.86h-.01Z"
          opacity=".59"
        />
        <path
          fill="url(#w)"
          d="M1194.83 1462.12c-256.3 108.67-552.17-11.02-660.84-267.3-108.67-256.29 11-552.16 267.3-660.84 256.29-108.67 552.16 11.02 660.82 267.31 108.67 256.29-11 552.16-267.29 660.83h.01ZM794.39 517.71C529.1 630.19 405.22 936.44 517.71 1201.73c112.48 265.29 418.73 389.16 684.02 276.68 265.29-112.48 389.16-418.73 276.68-684.02-112.48-265.29-418.73-389.16-684.01-276.68h-.01Z"
          opacity=".56"
        />
        {isHome === true ? (
          <path
            fill="url(#wc)"
            d="M1194.83 1462.12c-256.3 108.67-552.17-11.02-660.84-267.3-108.67-256.29 11-552.16 267.3-660.84 256.29-108.67 552.16 11.02 660.82 267.31 108.67 256.29-11 552.16-267.29 660.83h.01ZM794.39 517.71C529.1 630.19 405.22 936.44 517.71 1201.73c112.48 265.29 418.73 389.16 684.02 276.68 265.29-112.48 389.16-418.73 276.68-684.02-112.48-265.29-418.73-389.16-684.01-276.68h-.01Z"
            opacity=".56"
          />
        ) : null}
        <path
          fill="url(#x)"
          d="M688.88 1425.93C452.57 1255.17 399.45 925.16 570.2 688.87c170.75-236.29 500.75-289.44 737.06-118.68 236.29 170.75 289.42 500.76 118.67 737.05-170.75 236.29-500.75 289.44-737.04 118.69h-.01Zm628.72-870.06c-244.22-176.48-585.25-121.56-761.73 122.65-176.47 244.21-121.56 585.25 122.65 761.73 244.21 176.47 585.25 121.56 761.72-122.65 176.48-244.21 121.56-585.25-122.65-761.72l.01-.01Z"
          opacity=".65"
        />
        <path
          id="id-yc"
          fill="url(#y)"
          d="M1325.12 1442.38c-245.4 180.63-590.77 128.11-771.39-117.28-180.62-245.39-128.12-590.76 117.28-771.39C916.4 373.09 1261.77 425.61 1442.39 671s128.12 590.76-117.27 771.38ZM660.54 539.5C407.29 725.91 353.1 1082.32 539.5 1335.57c186.4 253.25 542.82 307.44 796.07 121.03 253.25-186.4 307.43-542.82 121.03-796.07-186.41-253.25-542.82-307.44-796.07-121.03h.01Z"
          opacity=".52"
        />
        {isHome === true ? (
          <path
            id="id-yc"
            fill="url(#yc)"
            d="M1325.12 1442.38c-245.4 180.63-590.77 128.11-771.39-117.28-180.62-245.39-128.12-590.76 117.28-771.39C916.4 373.09 1261.77 425.61 1442.39 671s128.12 590.76-117.27 771.38ZM660.54 539.5C407.29 725.91 353.1 1082.32 539.5 1335.57c186.4 253.25 542.82 307.44 796.07 121.03 253.25-186.4 307.43-542.82 121.03-796.07-186.41-253.25-542.82-307.44-796.07-121.03h.01Z"
            opacity=".52"
          />
        ) : null}
        <path
          fill="url(#z)"
          d="M1339.25 1461.58c-256 188.43-616.291 133.64-804.711-122.35-188.42-255.99-133.66-616.28 122.35-804.71 255.99-188.42 616.271-133.63 804.691 122.36 188.42 255.99 133.66 616.28-122.33 804.7ZM646.429 520.33c-263.85 194.21-320.3 565.52-126.1 829.37 194.2 263.84 565.521 320.3 829.371 126.09 263.84-194.2 320.29-565.52 126.09-829.36-194.2-263.84-565.521-320.3-829.361-126.1Z"
          opacity=".6"
        />
        {isHome === true ? (
          <path
            fill="url(#zc)"
            d="M1339.25 1461.58c-256 188.43-616.291 133.64-804.711-122.35-188.42-255.99-133.66-616.28 122.35-804.71 255.99-188.42 616.271-133.63 804.691 122.36 188.42 255.99 133.66 616.28-122.33 804.7ZM646.429 520.33c-263.85 194.21-320.3 565.52-126.1 829.37 194.2 263.84 565.521 320.3 829.371 126.09 263.84-194.2 320.29-565.52 126.09-829.36-194.2-263.84-565.521-320.3-829.361-126.1Z"
            opacity=".6"
          />
        ) : null}
        <path
          fill="url(#A)"
          d="M1043.56 1595.71c-330.08 25.12-618.02-222.11-643.15-552.17-25.12-330.06 222.09-618.02 552.17-643.15 330.06-25.12 618 222.11 643.13 552.17 25.12 330.06-222.09 618.02-552.15 643.14v.01ZM951.24 382.83c-339.78 25.86-594.26 322.27-568.4 662.05 25.86 339.78 322.27 594.26 662.06 568.4 339.78-25.86 594.26-322.28 568.4-662.05-25.86-339.78-322.27-594.26-662.05-568.4h-.01Z"
          opacity=".48"
        />
        <path
          fill="url(#B)"
          d="M1218.14 1581.12c-322.019 121.54-681.599-41-803.139-363-121.53-322 40.98-681.6 363-803.14 321.999-121.53 681.579 41 803.119 363.01 121.53 322-40.98 681.6-362.98 803.13ZM771.771 398.52c-331.12 124.98-498.23 494.71-373.26 825.83 124.97 331.11 494.71 498.23 825.829 373.25 331.11-124.97 498.22-494.71 373.25-825.82-124.97-331.12-494.71-498.23-825.819-373.25v-.01Z"
          opacity=".6"
        />
        <path
          fill="url(#C)"
          d="M1542.54 1347.64c-193.08 300.71-593.379 387.95-894.069 194.89-300.69-193.06-387.96-593.36-194.89-894.07 193.06-300.69 593.369-387.93 894.059-194.87 300.69 193.06 387.96 593.36 194.9 894.05ZM438.781 638.96c-198.32 308.89-108.7 720.05 200.18 918.38 308.88 198.32 720.049 108.7 918.379-200.19 198.32-308.88 108.69-720.05-200.19-918.37-308.88-198.32-720.049-108.7-918.369 200.18Z"
          opacity=".44"
        />
        <path
          fill="url(#D)"
          d="M998.069 1668.93c-370.52 0-670.88-300.38-670.88-670.88s300.36-670.87 670.88-670.87c370.521 0 670.861 300.38 670.861 670.88s-300.36 670.88-670.861 670.88v-.01Zm0-1359.33c-380.23 0-688.46 308.23-688.46 688.45s308.23 688.45 688.46 688.45c380.231 0 688.451-308.23 688.451-688.45S1378.29 309.6 998.069 309.6Z"
          opacity=".42"
        />
        <path
          fill="url(#E)"
          d="M998.071 1692.77c-383.68 0-694.71-311.05-694.71-694.71s311.03-694.71 694.71-694.71c383.679 0 694.689 311.05 694.689 694.71s-311.03 694.71-694.689 694.71Zm0-1406.98c-393.38 0-712.27 318.89-712.27 712.27s318.89 712.27 712.27 712.27c393.379 0 712.259-318.9 712.259-712.27s-318.89-712.27-712.259-712.27Z"
          opacity=".4"
        />
        <path
          fill="url(#F)"
          d="M998.071 1716.6c-396.84 0-718.54-321.72-718.54-718.54 0-396.82 321.7-718.54 718.54-718.54 396.839 0 718.519 321.72 718.519 718.54 0 396.82-321.7 718.54-718.519 718.54Zm-.01-1454.62c-406.53 0-736.09 329.55-736.09 736.08 0 406.53 329.55 736.08 736.09 736.08 406.539 0 736.079-329.56 736.079-736.08 0-406.52-329.55-736.08-736.079-736.08Z"
          opacity=".45"
        />
        {isHome === true ? (
          <path
            fill="url(#FC)"
            d="M998.071 1716.6c-396.84 0-718.54-321.72-718.54-718.54 0-396.82 321.7-718.54 718.54-718.54 396.839 0 718.519 321.72 718.519 718.54 0 396.82-321.7 718.54-718.519 718.54Zm-.01-1454.62c-406.53 0-736.09 329.55-736.09 736.08 0 406.53 329.55 736.08 736.09 736.08 406.539 0 736.079-329.56 736.079-736.08 0-406.52-329.55-736.08-736.079-736.08Z"
            opacity="1"
          />
        ) : null}
        <path
          fill="url(#G)"
          d="M317.669 1295.03c-164.01-375.77 7.68-813.35 383.43-977.35 375.751-164 813.341 7.66 977.351 383.43 164 375.75-7.69 813.33-383.44 977.33-375.751 164-813.341-7.66-977.341-383.41ZM1694.51 694.09C1526.63 309.44 1078.72 133.72 694.079 301.6c-384.64 167.88-560.36 615.78-392.48 1000.43 167.88 384.64 615.79 560.36 1000.421 392.48 384.64-167.88 560.36-615.78 392.48-1000.42h.01Z"
          opacity=".36"
        />
        {isHome === true ? (
          <path
            fill="url(#GC)"
            d="M317.669 1295.03c-164.01-375.77 7.68-813.35 383.43-977.35 375.751-164 813.341 7.66 977.351 383.43 164 375.75-7.69 813.33-383.44 977.33-375.751 164-813.341-7.66-977.341-383.41ZM1694.51 694.09C1526.63 309.44 1078.72 133.72 694.079 301.6c-384.64 167.88-560.36 615.78-392.48 1000.43 167.88 384.64 615.79 560.36 1000.421 392.48 384.64-167.88 560.36-615.78 392.48-1000.42h.01Z"
            opacity="1"
          />
        ) : null}
        <path
          fill="url(#H)"
          d="M505.021 1584.56c-323.91-272.3-365.74-755.64-93.45-1079.54 272.29-323.89 755.619-365.75 1079.539-93.45 323.89 272.29 365.72 755.63 93.43 1079.52s-755.619 365.75-1079.519 93.47Zm997.349-1186.4c-331.32-278.53-825.689-235.73-1104.209 95.58-278.52 331.31-235.73 825.69 95.59 1104.21 331.31 278.52 825.689 235.72 1104.209-95.59 278.52-331.32 235.73-825.69-95.58-1104.21l-.01.01Z"
          opacity=".34"
        />
        <path
          fill="url(#I)"
          d="M489.691 1602.8c-333.99-280.77-377.11-779.14-96.36-1113.11 280.76-333.97 779.129-377.13 1113.109-96.36 333.97 280.76 377.09 779.13 96.34 1113.1-280.76 333.97-779.129 377.13-1113.089 96.37ZM1517.7 379.93c-341.39-286.99-850.779-242.9-1137.769 98.49-286.99 341.38-242.89 850.78 98.49 1137.77s850.779 242.89 1137.759-98.49c286.99-341.38 242.89-850.78-98.49-1137.76l.01-.01Z"
          opacity=".32"
        />
        <path
          fill="url(#J)"
          d="M474.351 1621.04c-344.06-289.24-388.48-802.65-99.26-1146.69 289.22-344.04 802.629-388.5 1146.689-99.26 344.04 289.22 388.47 802.63 99.24 1146.67-289.22 344.04-802.629 388.5-1146.669 99.28ZM1533.02 361.7C1181.57 66.25 657.151 111.64 361.701 463.09c-295.45 351.45-250.06 875.87 101.4 1171.32 351.45 295.45 875.869 250.05 1171.319-101.4 295.45-351.45 250.06-875.87-101.39-1171.32l-.01.01Z"
          opacity=".3"
        />
        <path
          fill="url(#K)"
          d="M998.071 1835.75c-462.65 0-837.7-375.07-837.7-837.7 0-462.63 375.05-837.69 837.7-837.69 462.649 0 837.669 375.07 837.669 837.7 0 462.63-375.05 837.7-837.669 837.7v-.01Zm-.01-1692.86c-472.3 0-855.17 382.87-855.17 855.16 0 472.29 382.87 855.16 855.17 855.16 472.299 0 855.159-382.87 855.159-855.16 0-472.29-382.87-855.16-855.159-855.16Z"
          opacity=".27"
        />
        <path
          id="id-L"
          fill="url(#L)"
          d="M548.54 1733.02c-405.9-248.27-533.68-778.6-285.42-1184.48 248.26-405.89 778.57-533.7 1184.48-285.43 405.88 248.26 533.65 778.58 285.4 1184.46-248.26 405.88-778.57 533.69-1184.46 285.44v.01Zm908.16-1484.8C1042.57-5.08 501.52 125.3 248.22 539.42-5.08 953.54 125.3 1494.6 539.43 1747.9c414.12 253.29 955.17 122.92 1208.47-291.21 253.3-414.12 122.92-955.17-291.2-1208.47Z"
          opacity=".35"
        />
        {isHome === true ? (
          <path
            id="id-LC"
            fill="url(#LC)"
            d="M548.54 1733.02c-405.9-248.27-533.68-778.6-285.42-1184.48 248.26-405.89 778.57-533.7 1184.48-285.43 405.88 248.26 533.65 778.58 285.4 1184.46-248.26 405.88-778.57 533.69-1184.46 285.44v.01Zm908.16-1484.8C1042.57-5.08 501.52 125.3 248.22 539.42-5.08 953.54 125.3 1494.6 539.43 1747.9c414.12 253.29 955.17 122.92 1208.47-291.21 253.3-414.12 122.92-955.17-291.2-1208.47Z"
            opacity=".8"
          />
        ) : null}
        <path
          id="id-M"
          fill="url(#M)"
          d="M998.07 1883.41c-488.97 0-885.36-396.41-885.36-885.36S509.1 112.7 998.07 112.7s885.33 396.41 885.33 885.36-396.38 885.36-885.33 885.36v-.01Zm-.01-1788.15c-498.61 0-902.8 404.19-902.8 902.79 0 498.6 404.19 902.79 902.8 902.79 498.61 0 902.79-404.2 902.79-902.79S1496.66 95.26 998.06 95.26Z"
          opacity=".23"
        />
        {isHome === true ? (
          <path
            id="id-MC"
            fill="url(#MC)"
            d="M998.07 1883.41c-488.97 0-885.36-396.41-885.36-885.36S509.1 112.7 998.07 112.7s885.33 396.41 885.33 885.36-396.38 885.36-885.33 885.36v-.01Zm-.01-1788.15c-498.61 0-902.8 404.19-902.8 902.79 0 498.6 404.19 902.79 902.8 902.79 498.61 0 902.79-404.2 902.79-902.79S1496.66 95.26 998.06 95.26Z"
            opacity=".7"
          />
        ) : null}
        <path
          fill="url(#N)"
          d="M998.069 1907.25c-502.14 0-909.19-407.08-909.19-909.19 0-502.11 407.06-909.19 909.19-909.19 502.131 0 909.161 407.08 909.161 909.19 0 502.11-407.05 909.19-909.161 909.19Zm-.01-1835.8c-511.76 0-926.61 414.86-926.61 926.61s414.86 926.61 926.61 926.61c511.751 0 926.601-414.86 926.601-926.61S1509.81 71.45 998.059 71.45Z"
          opacity=".21"
        />
        <path
          fill="url(#O)"
          d="M1666.53 1648.95c-359.49 369.19-950.209 377.03-1319.369 17.56-369.16-359.47-377.05-950.19-17.56-1319.37C689.071-22.03 1279.79-29.87 1648.95 329.6c369.16 359.47 377.05 950.19 17.58 1319.35ZM317.121 335c-366.2 376.07-358.19 977.8 17.88 1343.99 376.07 366.19 977.789 358.19 1343.989-17.89 366.19-376.07 358.18-977.79-17.89-1343.98C1285.03-49.07 683.311-41.06 317.121 335Z"
          opacity=".3"
        />
        <path
          fill="url(#P)"
          d="M1316.34 1900.42c-498.37 175.77-1044.87-85.77-1220.63-584.11C-80.06 817.97 181.44 271.45 679.81 95.68c498.34-175.76 1044.84 85.78 1220.6 584.12 175.76 498.34-85.74 1044.86-584.08 1220.62h.01ZM674.02 79.28C166.59 258.25-99.68 814.68 79.29 1322.1c178.96 507.42 735.39 773.69 1242.82 594.72 507.42-178.96 773.68-735.4 594.72-1242.81-178.97-507.42-735.4-773.69-1242.81-594.73Z"
          opacity=".25"
        />
        <path
          fill="url(#Q)"
          d="M998.08 1978.74c-541.62 0-980.68-439.09-980.68-980.68 0-541.59 439.05-980.69 980.68-980.69 541.63 0 980.65 439.09 980.65 980.68 0 541.59-439.06 980.68-980.65 980.68v.01ZM998.07 0C446.84 0 0 446.84 0 998.06s446.84 998.06 998.06 998.06 998.05-446.85 998.05-998.06C1996.11 446.85 1549.27 0 998.06 0h.01Z"
          opacity=".15"
        />

        <defs>
          <linearGradient
            id="b"
            x1="982.772"
            x2="1013.52"
            y1="1013.31"
            y2="982.923"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="c"
            x1="755.515"
            x2="820.132"
            y1="817.294"
            y2="753.417"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="d"
            x1="738.576"
            x2="837.066"
            y1="834.04"
            y2="736.678"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="e"
            x1="721.642"
            x2="854.005"
            y1="850.779"
            y2="719.932"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="f"
            x1="881.18"
            x2="1114.93"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="g"
            x1="687.77"
            x2="887.879"
            y1="884.264"
            y2="686.447"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="h"
            x1="670.829"
            x2="904.818"
            y1="901.01"
            y2="669.701"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="i"
            x1="864.219"
            x2="1132.07"
            y1="1130.51"
            y2="865.722"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="j"
            x1="847.28"
            x2="1149.01"
            y1="1147.25"
            y2="848.976"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="k"
            x1="620.016"
            x2="955.623"
            y1="951.241"
            y2="619.477"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="l"
            x1="763.366"
            x2="1232.61"
            y1="886.658"
            y2="1109.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="m"
            x1="741.852"
            x2="1254.11"
            y1="876.434"
            y2="1119.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="n"
            x1="720.346"
            x2="1275.62"
            y1="866.213"
            y2="1130.12"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="o"
            x1="1306.82"
            x2="689.636"
            y1="1118.4"
            y2="877.775"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="p"
            x1="658.149"
            x2="1338.09"
            y1="895.646"
            y2="1100.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="q"
            x1="641.466"
            x2="1354.51"
            y1="1126.2"
            y2="869.915"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="r"
            x1="612.114"
            x2="1384.12"
            y1="1112.68"
            y2="883.411"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="s"
            x1="571.58"
            x2="1424.54"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="t"
            x1="547.76"
            x2="1448.35"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="u"
            x1="523.951"
            x2="1472.17"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="v"
            x1="523.306"
            x2="1472.92"
            y1="1148.02"
            y2="848.058"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="w"
            x1="517.772"
            x2="1478.44"
            y1="1201.75"
            y2="794.365"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="x"
            x1="555.948"
            x2="1440.35"
            y1="678.427"
            y2="1317.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="y"
            x1="539.637"
            x2="1456.68"
            y1="1335.66"
            y2="660.547"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="z"
            x1="520.453"
            x2="1475.85"
            y1="1349.79"
            y2="646.432"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="A"
            x1="382.779"
            x2="1613.23"
            y1="1044.89"
            y2="951.296"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="B"
            x1="357.061"
            x2="1639.05"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="C"
            x1="639.086"
            x2="1357.22"
            y1="1557.43"
            y2="438.831"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="D"
            x1="309.599"
            x2="1686.51"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="E"
            x1="285.791"
            x2="1710.32"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="F"
            x1="261.971"
            x2="1734.14"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="G"
            x1="694.122"
            x2="1302.09"
            y1="301.561"
            y2="1694.46"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="H"
            x1="398.223"
            x2="1598.06"
            y1="493.656"
            y2="1502.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="I"
            x1="379.997"
            x2="1616.3"
            y1="478.336"
            y2="1517.56"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="J"
            x1="361.763"
            x2="1634.52"
            y1="463.008"
            y2="1532.88"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="K"
            x1="142.891"
            x2="1853.22"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="L"
            x1="248.261"
            x2="1747.96"
            y1="539.375"
            y2="1456.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="M"
            x1="95.26"
            x2="1900.85"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="N"
            x1="71.449"
            x2="1924.67"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="O"
            x1="334.918"
            x2="1661.08"
            y1="1678.93"
            y2="317.116"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="P"
            x1="79.347"
            x2="1916.86"
            y1="1322.13"
            y2="673.962"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="Q"
            x1="0"
            x2="1996.11"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={colorSecondary} stopOpacity=".1" />
            <stop offset=".06" stopColor={colorSecondary} stopOpacity=".12" />
            <stop offset=".13" stopColor={colorSecondary} stopOpacity=".18" />
            <stop offset=".21" stopColor={colorSecondary} stopOpacity=".27" />
            <stop offset=".28" stopColor={colorSecondary} stopOpacity=".41" />
            <stop offset=".36" stopColor={colorSecondary} stopOpacity=".58" />
            <stop offset=".44" stopColor={colorSecondary} stopOpacity=".78" />
            <stop offset=".51" stopColor={colorSecondary} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <clipPath id="a">
            <path fill="#fff" d="M0 0h1996.11v1996.11H0z" />
          </clipPath>
          {/* Custom Home parts */}
          <linearGradient
            id="GC"
            x1="694.122"
            x2="1302.09"
            y1="301.561"
            y2="1694.46"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--green-business)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--green-business)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--green-business)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--green-business)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--green-business)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--green-business)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--green-business)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--green-business)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="FC"
            x1="261.971"
            x2="1734.14"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lime-net-zero)'} stopOpacity="0.7" />
            <stop offset=".06" stopColor={'var(--lime-net-zero)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lime-net-zero)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lime-net-zero)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lime-net-zero)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--lime-net-zero)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lime-net-zero)'} stopOpacity="1" />
            <stop offset=".51" stopColor={'var(--lime-net-zero)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="LC"
            x1="248.261"
            x2="1747.96"
            y1="539.375"
            y2="1456.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lilac-space)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lilac-space)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lilac-space)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lilac-space)'} stopOpacity="1" />
            <stop offset=".28" stopColor={'var(--lilac-space)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--lilac-space)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lilac-space)'} stopOpacity="1" />
            <stop offset=".51" stopColor={'var(--lilac-space)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="MC"
            x1="95.26"
            x2="1900.85"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lilac-space)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lilac-space)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lilac-space)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lilac-space)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lilac-space)'} stopOpacity=".8" />
            <stop offset=".36" stopColor={'var(--lilac-space)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lilac-space)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--lilac-space)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="zc"
            x1="520.453"
            x2="1475.85"
            y1="1349.79"
            y2="646.432"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lilac-space)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lilac-space)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lilac-space)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lilac-space)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lilac-space)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--lilac-space)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lilac-space)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--lilac-space)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="yc"
            x1="539.637"
            x2="1456.68"
            y1="1335.66"
            y2="660.547"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--green-business)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--green-business)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--green-business)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--green-business)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--green-business)'} stopOpacity="1" />
            <stop offset=".36" stopColor={'var(--green-business)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--green-business)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--green-business)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="wc"
            x1="517.772"
            x2="1478.44"
            y1="1201.75"
            y2="794.365"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lime-net-zero)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lime-net-zero)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lime-net-zero)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lime-net-zero)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lime-net-zero)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--lime-net-zero)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lime-net-zero)'} stopOpacity="1" />
            <stop offset=".51" stopColor={'var(--lime-net-zero)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="sc"
            x1="571.58"
            x2="1424.54"
            y1="998.06"
            y2="998.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lime-net-zero)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lime-net-zero)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lime-net-zero)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lime-net-zero)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lime-net-zero)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--lime-net-zero)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lime-net-zero)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--lime-net-zero)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="rc"
            x1="612.114"
            x2="1384.12"
            y1="1112.68"
            y2="883.411"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lilac-space)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lilac-space)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lilac-space)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lilac-space)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lilac-space)'} stopOpacity="11" />
            <stop offset=".36" stopColor={'var(--lilac-space)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lilac-space)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--lilac-space)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="qc"
            x1="641.466"
            x2="1354.51"
            y1="1126.2"
            y2="869.915"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--green-business)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--green-business)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--green-business)'} stopOpacity=".7" />
            <stop offset=".21" stopColor={'var(--green-business)'} stopOpacity=".8" />
            <stop offset=".28" stopColor={'var(--green-business)'} stopOpacity=".9" />
            <stop offset=".36" stopColor={'var(--green-business)'} stopOpacity="1" />
            <stop offset=".44" stopColor={'var(--green-business)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--green-business)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="pc"
            x1="658.149"
            x2="1338.09"
            y1="895.646"
            y2="1100.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--green-business)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--green-business)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--green-business)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--green-business)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--green-business)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--green-business)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--green-business)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--green-business)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="oc"
            x1="1306.82"
            x2="689.636"
            y1="1118.4"
            y2="877.775"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lime-net-zero)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lime-net-zero)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lime-net-zero)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lime-net-zero)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lime-net-zero)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--lime-net-zero)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lime-net-zero)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--lime-net-zero)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="mc"
            x1="741.852"
            x2="1254.11"
            y1="876.434"
            y2="1119.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--green-business)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--green-business)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--green-business)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--green-business)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--green-business)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--green-business)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--green-business)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--green-business)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="hc"
            x1="670.829"
            x2="904.818"
            y1="901.01"
            y2="669.701"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lilac-space)'} stopOpacity="1" />
            <stop offset=".06" stopColor={'var(--lilac-space)'} stopOpacity="1" />
            <stop offset=".13" stopColor={'var(--lilac-space)'} stopOpacity="1" />
            <stop offset=".21" stopColor={'var(--lilac-space)'} stopOpacity=".8" />
            <stop offset=".28" stopColor={'var(--lilac-space)'} stopOpacity=".7" />
            <stop offset=".36" stopColor={'var(--lilac-space)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lilac-space)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--lilac-space)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="gc"
            x1="687.77"
            x2="887.879"
            y1="884.264"
            y2="686.447"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--lime-net-zero)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--lime-net-zero)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--lime-net-zero)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--lime-net-zero)'} stopOpacity=".27" />
            <stop offset=".28" stopColor={'var(--lime-net-zero)'} stopOpacity=".41" />
            <stop offset=".36" stopColor={'var(--lime-net-zero)'} stopOpacity=".58" />
            <stop offset=".44" stopColor={'var(--lime-net-zero)'} stopOpacity=".78" />
            <stop offset=".51" stopColor={'var(--lime-net-zero)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
          <linearGradient
            id="ic"
            x1="864.219"
            x2="1132.07"
            y1="1130.51"
            y2="865.722"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={'var(--green-business)'} stopOpacity=".1" />
            <stop offset=".06" stopColor={'var(--green-business)'} stopOpacity=".12" />
            <stop offset=".13" stopColor={'var(--green-business)'} stopOpacity=".18" />
            <stop offset=".21" stopColor={'var(--green-business)'} stopOpacity=".7" />
            <stop offset=".28" stopColor={'var(--green-business)'} stopOpacity=".8" />
            <stop offset=".36" stopColor={'var(--green-business)'} stopOpacity="..9" />
            <stop offset=".44" stopColor={'var(--green-business)'} stopOpacity="1" />
            <stop offset=".51" stopColor={'var(--green-business)'} />
            <stop offset="1" stopColor={colorPrimary} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export default CirclesPatternBig
