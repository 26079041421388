const SpotifyColorIcon = ({ width = 40, className = '' }) => {
  const ratio = 40 / 40
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <a
        href="https://open.spotify.com/show/7MjrCTEV18O23rADaqUKwT?si=PGuPCOqLTGCxqdNH1IZEIQ&nd=1"
        aria-label="Go to Spotify podcast"
        target="_blanc"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="none"
          viewBox="0 0 40 40"
        >
          <circle cx="19.998" cy="20" r="18.947" fill="#000" />
          <path
            fill="#1ED760"
            d="M20 0C9.01 0 0 9.01 0 20s9.01 20 20 20c11.09 0 20-9.01 20-20S31.09 0 20 0Zm9.208 28.91c-.396.595-1.09.793-1.683.397-4.753-2.871-10.693-3.564-17.624-1.98-.693.198-1.386-.297-1.485-.891-.1-.594.297-1.387.89-1.485 7.625-1.783 14.16-.99 19.407 2.277.693.297.891 1.089.495 1.683Zm2.475-5.445c-.495.693-1.386.99-2.178.495-5.347-3.267-13.564-4.257-20-2.376-.792.297-1.683-.198-1.98-1.089-.297-.792.198-1.683 1.089-1.98 7.228-2.178 16.337-1.09 22.475 2.673.792.495.99 1.485.594 2.277Zm.198-5.742c-6.436-3.862-17.129-4.159-23.267-2.277-.99.297-2.08-.297-2.376-1.288-.297-.99.297-2.079 1.287-2.376 7.03-2.178 18.812-1.782 26.237 2.673.892.595 1.09 1.684.495 2.575-.495.89-1.584 1.188-2.376.693Z"
          />
        </svg>
      </a>
    </div>
  )
}

export default SpotifyColorIcon
