const BulletPoint = ({
  color = 'var(--red-home)',
  colorTwo = 'var(--black)',
  width = 61,
  className = '',
}) => {
  const ratio = 61 / 76
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 61 76">
        <path
          fill={color}
          fillRule="evenodd"
          d="M39 44a5 5 0 1 0 0-10 5 5 0 0 0 0 10Zm0 5c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10Z"
          clipRule="evenodd"
        />
        <path
          fill={colorTwo}
          fillRule="evenodd"
          d="M39 53c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14Zm0 5c10.493 0 19-8.507 19-19s-8.507-19-19-19-19 8.507-19 19 8.507 19 19 19Z"
          clipRule="evenodd"
          opacity=".15"
        />
        <path
          fill={colorTwo}
          fillRule="evenodd"
          d="M56.866 25.692c-7.42-9.968-21.515-12.034-31.483-4.614-9.968 7.42-12.034 21.515-4.615 31.483l-4.01 2.986c-9.07-12.183-6.544-29.411 5.64-38.48C34.58 8 51.807 10.524 60.876 22.707l-4.01 2.985Z"
          clipRule="evenodd"
          opacity=".1"
        />
        <path
          fill={colorTwo}
          fillRule="evenodd"
          d="M39 7.5C21.603 7.5 7.5 21.603 7.5 39S21.603 70.5 39 70.5v5C18.842 75.5 2.5 59.158 2.5 39S18.842 2.5 39 2.5v5Z"
          clipRule="evenodd"
          opacity=".05"
        />
      </svg>
    </div>
  )
}

export default BulletPoint
