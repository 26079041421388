import React from 'react'
import './MusicPlatformsSection.css'
import AppleIcon from '../../components/_SVG/AppleIcon'
import SpotifyIcon from '../../components/_SVG/SpotifyIcon'
import DeezerIcon from '../../components/_SVG/DeezerIcon'
import SoundCloudIcon from '../../components/_SVG/SoundCloundIcon'

const MusicPlatformsSection = ({ Title = 'Subscribe & Listen' }) => {
  return (
    <div className="music-platforms-block">
      <h2>{Title}</h2>
      <div className="music-platforms-list">
        <div className="music-platforms-platform">
          <AppleIcon className="mb-3" />
          <h3 className="text-center">Apple podcast</h3>
        </div>
        <div className="music-platforms-platform">
          <SpotifyIcon className="mb-3" />
          <h3 className="text-center">Spotify</h3>
        </div>
        <div className="music-platforms-platform">
          <DeezerIcon className="mb-3" />
          <h3 className="text-center">Deezer</h3>
        </div>
        <div className="music-platforms-platform">
          <SoundCloudIcon className="mb-3" />
          <h3 className="text-center">Soundcloud</h3>
        </div>
      </div>
    </div>
  )
}

export default MusicPlatformsSection
