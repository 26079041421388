import Page from './_Page/Page.jsx'
import Section from '../sections/_Section/Section.jsx'
import HeroSection from '../sections/HeroSection/HeroSection.jsx'
import FooterSection from '../sections/FooterSection/FooterSection.jsx'
import MotionAnimation from '../components/Transition/MotionAnimation.jsx'
import { Helmet } from 'react-helmet-async'
import { useEffect } from 'react'

const CookiePolicy = ({ colorPrimary, colorSecondary, previousColor }) => {
  const favicon =
    document.querySelector("link[rel='icon']") ||
    document.querySelector("link[rel='shortcut icon']")

  useEffect(() => {
    favicon.href = '/favicon.svg'
  }, [location.pathname])
  return (
    <>
      <Helmet>
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <meta
          name="description"
          content="LuxUnplugged Podcast: Explore Luxembourg's business landscape, space companies and initiatives, and environmental sustainability with Adrien Loesch and Thierry Schoen."
          data-rh="true"
        />
        <meta
          name="keywords"
          content="Luxunplugged podcast, Business Insights Luxembourg, Luxembourg Business Ecosystem, Entrepreneurship in Luxembourg, Space Companies Luxembourg, Adrien Loesch, Thierry Schoen, Kirill Mitsurov, Elena Sychkova"
        />
      </Helmet>
      <Page className="CookiePolicy" genericPage={true}>
        <MotionAnimation color={colorPrimary} previousColor={previousColor} />
        <HeroSection
          isHome
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
          subtitle="of Luxunplugged podcast"
          description=""
          height="600px"
        />
        <Section
          oneColumnLayout
          className={'PodcastDescriptionSection'}
          colorPrimary={colorPrimary}
          color="var(--white)"
        >
          <div className="page-content">
            <p>
              This document informs Users about the technologies that help this Application to
              achieve the purposes described below. Such technologies allow the Owner to access and
              store information (for example by using a Cookie) or use resources (for example by
              running a script) on a User’s device as they interact with this Application.
              <br />
              For simplicity, all such technologies are defined as "Trackers" within this document –
              unless there is a reason to differentiate.
              <br />
              For example, while Cookies can be used on both web and mobile browsers, it would be
              inaccurate to talk about Cookies in the context of mobile apps as they are a
              browser-based Tracker.
              <br />
              For this reason, within this document, the term Cookies is only used where it is
              specifically meant to indicate that particular type of Tracker. Some of the purposes
              for which Trackers are used may also require the User's consent. Whenever consent is
              given, it can be freely withdrawn at any time following the instructions provided in
              this document.
              <br />
              This Application uses Trackers managed directly by the Owner (so-called “first-party”
              Trackers) and Trackers that enable services provided by a third-party (so-called
              “third-party” Trackers). Unless otherwise specified within this document, third-party
              providers may access the Trackers managed by them.
              <br />
              The validity and expiration periods of Cookies and other similar Trackers may vary
              depending on the lifetime set by the Owner or the relevant provider. Some of them
              expire upon termination of the User’s browsing session.
              <br />
              In addition to what’s specified in the descriptions within each of the categories
              below, Users may find more precise and updated information regarding lifetime
              specification as well as any other relevant information — such as the presence of
              other Trackers — in the linked privacy policies of the respective third-party
              providers or by contacting the Owner.
            </p>
            <h3>
              Activities strictly necessary for the operation of this Application and delivery of
              the Service
            </h3>
            <p>
              This Application uses so-called “technical” Cookies and other similar Trackers to
              carry out activities that are strictly necessary for the operation or delivery of the
              Service.
            </p>
            <h3>Other activities involving the use of Trackers</h3>
            <h4>Measurement</h4>
            <p>
              This Application uses Trackers to measure traffic and analyze User behavior to improve
              the Service.
            </p>
            <h4>Analytics</h4>
            <h3>How to manage preferences and provide or withdraw consent</h3>
            <p>
              There are various ways to manage Tracker related preferences and to provide and
              withdraw consent, where relevant:
              <br />
              Users can manage preferences related to Trackers from directly within their own device
              settings, for example, by preventing the use or storage of Trackers.
              <br />
              Additionally, whenever the use of Trackers is based on consent, Users can provide or
              withdraw such consent by setting their preferences within the cookie notice or by
              updating such preferences accordingly via the relevant consent-preferences privacy
              widget, if available.
              <br />
              It is also possible, via relevant browser or device features, to delete previously
              stored Trackers, including those used to remember the User’s initial consent
              preferences.
              <br /> Other Trackers in the browser’s local memory may be cleared by deleting the
              browsing history. With regard to any third-party Trackers, Users can manage their
              preferences via the related opt-out link (where provided), by using the means
              indicated in the third party's privacy policy, or by contacting the third party.
            </p>
            <h4>Locating Tracker Settings</h4>
            <p>
              Users can, for example, find information about how to manage Cookies in the most
              commonly used browsers at the following addresses:
            </p>
            <ul>
              <li>Google Chrome</li>
              <li>Mozilla Firefox</li>
              <li>Apple Safari</li>
              <li>Microsoft Internet Explorer</li>
              <li>Microsoft Edge</li>
              <li>Brave</li>
              <li>Opera</li>
            </ul>
            <p>
              Users may also manage certain categories of Trackers used on mobile apps by opting out
              through relevant device settings such as the device advertising settings for mobile
              devices, or tracking settings in general (Users may open the device settings and look
              for the relevant setting).
            </p>
            <h4>Consequences of denying the use of Trackers</h4>
            <p>
              Users are free to decide whether or not to allow the use of Trackers. However, please
              note that Trackers help this Application to provide a better experience and advanced
              functionalities to Users (in line with the purposes outlined in this document).
              Therefore, if the User chooses to block the use of Trackers, the Owner may be unable
              to provide related features.
            </p>
            <h3>Owner and Data Controller</h3>
            <p>
              © 2024 Luxembourg Business Vision Ltd. Company Registered in the UK 13660945 Owner
              contact email: info@luxunplugged.com
            </p>
            <p>
              Since the use of third-party Trackers through this Application cannot be fully
              controlled by the Owner, any specific references to third-party Trackers are to be
              considered indicative. In order to obtain complete information, Users are kindly
              requested to consult the privacy policies of the respective third-party services
              listed in this document. Given the objective complexity surrounding tracking
              technologies, Users are encouraged to contact the Owner should they wish to receive
              any further information on the use of such technologies by this Application.
            </p>

            <p className="mt-5">Latest update: December 14, 2023</p>
            <p>Show the complete Privacy Policy</p>
          </div>
        </Section>

        <FooterSection />
      </Page>
    </>
  )
}

export default CookiePolicy
