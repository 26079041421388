const SpotifyIcon = ({ color = 'var(--white)', width = 80, className = '' }) => {
  const ratio = 80 / 80
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <a
        href="https://open.spotify.com/show/7MjrCTEV18O23rADaqUKwT?si=PGuPCOqLTGCxqdNH1IZEIQ&nd=1"
        aria-label="Go to Spotify podcast"
        target="_blanc"
      >
        <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" fill="none">
          <path
            fill={color}
            d="M40 0C17.904 0 0 17.904 0 40s17.904 40 40 40 40-17.904 40-40S62.096 0 40 0Zm18.336 57.696c-.72 1.168-2.256 1.552-3.424.832-9.392-5.744-21.216-7.04-35.136-3.856a2.513 2.513 0 0 1-3.04-1.792 2.513 2.513 0 0 1 1.792-3.04c.048-.016.08-.016.128-.032 15.232-3.488 28.304-1.984 38.864 4.464a2.461 2.461 0 0 1 .816 3.424ZM63.232 46.8c-.896 1.472-2.816 1.92-4.288 1.024-10.752-6.608-27.136-8.512-39.856-4.656A3.12 3.12 0 0 1 17.28 37.2c14.528-4.4 32.592-2.272 44.928 5.312a3.101 3.101 0 0 1 1.024 4.288Zm.416-11.344c-12.88-7.664-34.16-8.368-46.464-4.624a3.739 3.739 0 0 1-4.656-2.496 3.739 3.739 0 0 1 2.496-4.656c14.128-4.288 37.616-3.456 52.464 5.344A3.75 3.75 0 0 1 68.8 34.16a3.75 3.75 0 0 1-5.152 1.296Z"
          />
        </svg>
      </a>
    </div>
  )
}

export default SpotifyIcon
