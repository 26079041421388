import './H3Title.css'
import BulletPoint from '../_SVG/BulletPoint'

const H3Title = ({
  isHomeColor = false,
  colorSecondary = '',
  colorTwo = 'var(--black)',
  text = 'Latest Episode',
  textColor = 'var(--black)',
}) => {
  return (
    <div className="H3Title d-flex align-items-center mb-3 mb-sm-3">
      <BulletPoint color={isHomeColor ? 'var(--red-home)' : colorSecondary} colorTwo={colorTwo} />
      <h3 className="mb-0 ms-3" style={{ color: textColor }}>
        {text}
      </h3>
    </div>
  )
}
export default H3Title
