import './Page.css'
import React, { useState, useEffect } from 'react'

const Page = ({ children, className = '', genericPage, colorPrimary, pathName }) => {
  const [dynamicClassName, setDynamicClassName] = useState('')

  useEffect(() => {
    const classChecker = () => {
      if (colorPrimary === 'var(--grey-home') {
        return setDynamicClassName('HomePage')
      } else if (colorPrimary === 'var(--violet-business)') {
        return setDynamicClassName('BusinessPodcastPage')
      } else if (colorPrimary === 'var(--blue-net-zero)') {
        return setDynamicClassName('NetZeroFuturePage')
      } else if (colorPrimary === 'var(--deep-blue-space)') {
        return setDynamicClassName('DarkMatterUncoveredPage')
      } else {
        return setDynamicClassName('HomePage')
      }
    }
    setTimeout(() => {
      classChecker()
    }, 2000)
  }, [pathName]) // Dependency array, if any dependencies change, the effect will re-run

  return (
    <main className={`Page ${className} ${dynamicClassName} ${genericPage ? 'generic-page' : ''}`}>
      {children}
    </main>
  )
}

export default Page
