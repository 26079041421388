import './TriangularGroup.css'
import Triangular from './Triangular'

const TriangularGroup = ({
  colorSecondary = '',
  colorOne = 'var(--green-business)',
  colorTwo = 'var(--lime-net-zero)',
  colorThree = 'var(--white)',
  isHome = false,
  className = '',
  TriangularWidthOne = 38,
  TriangularWidthTwo = 23,
  TriangularWidthThree = 18,
}) => {
  return (
    <div className={`TriangularGroupWrapper ${className}`}>
      <div className="TriangularGroup position-relative">
        <Triangular
          width={TriangularWidthOne}
          className={'Triangular one'}
          color={isHome === true ? colorOne : colorSecondary}
        />
        <Triangular width={TriangularWidthTwo} className={'Triangular two'} color={colorThree} />
        <Triangular
          width={TriangularWidthThree}
          className={'Triangular three'}
          fillColor={false}
          color={isHome === true ? colorTwo : colorSecondary}
        />
      </div>
    </div>
  )
}

export default TriangularGroup
