const Triangular = ({
  color = 'var(--green-business)',
  width = 30,
  className = '',
  fillColor = true,
}) => {
  const ratio = 30 / 34
  const height = width / ratio

  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 34">
        <path
          fill={color}
          fillRule={fillColor === true ? null : 'evenodd'}
          d="M26.786 17 3 3.184v27.632L26.786 17Zm1.835 2.404c1.839-1.069 1.839-3.74 0-4.807L4.138.376C2.298-.692 0 .643 0 2.779v28.442c0 2.136 2.299 3.471 4.138 2.403l24.483-14.22Z"
          clipRule={fillColor === true ? null : 'evenodd'}
        />
      </svg>
    </div>
  )
}

export default Triangular
