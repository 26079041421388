import './MobileMenuButton.css'

const MobileMenuButton = ({ color = 'var(--red-home)', className = '' }) => {
  return (
    <button
      type="button"
      aria-label={'Menu'}
      className={`MobileMenuButton ${className}`}
      style={{ backgroundColor: color }}
    >
      <span className="bar-one"></span>
      <span className="bar-two"></span>
      <span className="bar-three"></span>
    </button>
  )
}

export default MobileMenuButton
