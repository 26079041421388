import Tag from '../Tag/Tag'
import PlayButton from '../PlayButton/PlayButton'
import './EpisodeList.css'
import { Link } from 'react-router-dom'

const EpisodeList = ({
  colorPrimary = 'var(--violet-business)',
  colorSecondary = 'var(--green-business)',
  index = '01',
  className = '',
  title = 'The growing role of corporate social responsibility in banking',
  name = 'Françoise Thoma',
  date = '23.06.2023',
  number = '013',
  episodeId = '',
}) => {
  return (
    <div className={`EpisodeList ${className}`}>
      <Link to={`/episode/${episodeId}`} aria-label="Go to the episode">
        <Tag colorPrimary={colorPrimary} className="-sm mb-1" />
        <div className="info">
          <div className="episode-number me-sm-3 me-md-3 me-lg-3">{number}</div>
          <span className="title me-sm-3 me-md-3 me-lg-3">{title}</span>
          <div className="name-date">
            <p className="name mt-2 mt-sm-0 mt-md-0 mt-lg-0 me-sm-3 me-md-3 me-lg-3">{name}</p>
            <span className="date mt-2 mt-sm-0 mt-md-0 mt-lg-0 me-sm-3 me-md-3 me-lg-3">
              {date}
            </span>
          </div>
        </div>
        <div className="listen">
          <PlayButton width={32} colorPrimary={'var(--black)'} colorSecondary={'var(--black)'} />
          <span className="ms-2">Listen</span>
        </div>
      </Link>
    </div>
  )
}

export default EpisodeList
