import { Link } from 'react-router-dom'
import EpisodeCard from '../../components/EpisodeCard/EpisodeCard'
import H3Title from '../../components/H3Tittle/H3Title'
import { getColorsBySeriesName } from '../../cross-page-functions'
import { images_list_by_id } from '../../images'
const FeaturedEpisodes = ({ colorSecondary = '', isHomeColor, FeaturedEpisodesList }) => {
  if (FeaturedEpisodesList != false) {
    return (
      <div className="container position-relative">
        <div className="row">
          <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <H3Title
              isHomeColor={isHomeColor}
              colorSecondary={colorSecondary}
              text="Featured Episodes"
            />
          </div>
          <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
            <div className="row mobile">
              {FeaturedEpisodesList.map((episode, index) => {
                let imagesrc = '',
                  nocover
                if (
                  episode.id != null &&
                  images_list_by_id[episode.id] != undefined &&
                  images_list_by_id[episode.id].img_src != undefined
                ) {
                  imagesrc = '/img/cover/' + images_list_by_id[episode.id].img_src
                } else {
                  nocover = true
                }
                let [primary, secondary] = getColorsBySeriesName(episode.series)
                return (
                  <div
                    key={index}
                    className="col-sm-6 col-lg-6 col-xl-6 col-xxl-4 d-flex justify-content-center"
                  >
                    <Link
                      className="d-flex flex-grow-1 justify-content-center"
                      to={'/episode/' + episode.id}
                      aria-label="Go to the episode"
                    >
                      <EpisodeCard
                        className="my-3"
                        episodeNumber={episode.episode_number}
                        index={`EpisodeCard${index}`}
                        colorPrimary={primary}
                        colorSecondary={secondary}
                        imageSrc={imagesrc}
                        imageAlt={episode.guest}
                        title={episode.title}
                        name={episode.guest}
                        noCoverImage={nocover}
                      />
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FeaturedEpisodes
