import { Link } from 'react-router-dom'
import './Menu.css'

const Menu = ({ onClick }) => {
  const handleClick = () => {
    // Call the parent's click handler when the child is clicked
    onClick()
  }
  return (
    <nav className="Menu">
      <ul className="m-0 p-0">
        <li className="me-2" onClick={handleClick}>
          PODCASTS
        </li>
        <Link to="/about">
          <li className="ms-2">ABOUT US</li>
        </Link>
      </ul>
    </nav>
  )
}

export default Menu
