import Button from '../Button/Button'
import H3Title from '../H3Tittle/H3Title'
import './CookiesPanel.css'
import { Link } from 'react-router-dom'

const CookiesPanel = ({
  className = '',
  cookiesAllowed,
  setCookiesAllowed,
  makeCookie,
  cookiesNames,
}) => {
  const handleClick = () => {
    makeCookie(cookiesNames.accepted, 'true')

    setCookiesAllowed(true)
    console.log('cookies accepted')
  }
  if (cookiesAllowed == false || cookiesAllowed == true) {
    return (
      <div className={`CookiesPanel ${!cookiesAllowed ? 'showCookies' : ''} ${className}`}>
        <img width={'301px'} height={'220px'} src="/img/Cookies.png" alt="We love cookies" />
        <div className="cookies-content mb-3">
          <H3Title
            text="Cookies"
            colorTwo="var(--white)"
            textColor="var(--white)"
            colorSecondary="var(--red-home)"
          />
          <p>We use cookies to make your experience better</p>
          <div className="content-footer">
            <Link to="/privacy-policy">
              <Button ariaLabel={'Privacy Policy'} text={'Privacy Policy'} secondary={true} />
            </Link>
            <span onClick={handleClick}>
              <Button
                type="submit"
                ariaLabel={'Accept All Cookies'}
                className="submit"
                text={'Accept All'}
                fixedWidth={'189.2px'}
              />
            </span>
          </div>
        </div>
      </div>
    )
  }
}

export default CookiesPanel
