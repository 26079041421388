import Page from './_Page/Page.jsx'
import Section from '../sections/_Section/Section.jsx'
import HeroSection from '../sections/HeroSection/HeroSection.jsx'
import FooterSection from '../sections/FooterSection/FooterSection.jsx'
import AboutDescriptionSection from '../sections/AboutDescriptionSection/AboutDescriptionSection.jsx'
import Partners from '../sections/PartnersSection/Partners.jsx'
import OurTeamSection from '../sections/OurTeamSection/OurTeamSection.jsx'
import CirclesCroppedGroup from '../components/_SVG/CirclesCroppedGroup.jsx'
import MotionAnimation from '../components/Transition/MotionAnimation.jsx'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const AboutPage = ({ colorPrimary, colorSecondary, previousColor }) => {
  const favicon =
    document.querySelector("link[rel='icon']") ||
    document.querySelector("link[rel='shortcut icon']")

  useEffect(() => {
    favicon.href = '/favicon.svg'
  }, [location.pathname])
  return (
    <>
      <Helmet>
        <title>About LuxUnplugged Podcast</title>
        <meta
          name="description"
          content="Discover LuxUnplugged Podcast and meet our team, including hosts Adrien Loesch and Thierry Schoen, along with other members Kirill Mitsurov and Elena Sychkova."
          data-rh="true"
        />
        <meta
          name="keywords"
          content="LuxUnplugged Podcast, LuxUnplugged Podcast Team, LuxUnplugged hosts, Adrien Loesch, Thierry Schoen, Kirill Mitsurov, Elena Sychkova"
        />
      </Helmet>
      <Page className="AboutPage">
        <MotionAnimation color={colorPrimary} previousColor={previousColor} />
        <HeroSection
          title={'LUXUNPLUGGED TEAM'}
          subtitle=""
          description=""
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
          isHome
        />
        <AboutDescriptionSection colorPrimary={colorPrimary} colorSecondary={'var(--red-home)'} />
        <Section
          colorPrimary={colorPrimary}
          className="OurTeamSection position-relative"
          overflowType="hidden"
        >
          <CirclesCroppedGroup color={colorPrimary} width={2000} circlesOpacity={0.2} />
          <OurTeamSection isHomeColor={true} colorSecondary={colorSecondary} />
        </Section>
        <Section
          colorPrimary={colorPrimary}
          color={'var(--white)'}
          className="Partners"
          sectionTitle={'ALL TOGETHER'}
        >
          <Partners isHomeColor title="Our Partners" />
        </Section>

        <FooterSection />
      </Page>
    </>
  )
}

export default AboutPage
