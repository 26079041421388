import './Tag.css'

const Tag = ({ colorPrimary = 'var(--violet-business)', width = '', className = '', style }) => {
  const tagText = ['Business podcast', 'Net zero future', 'Dark matter Uncovered']
  return (
    <div
      className={`Tag ${className}`}
      style={{
        backgroundColor: colorPrimary,
        ...style,
      }}
    >
      {colorPrimary === 'var(--violet-business)' ? tagText[0] : null}
      {colorPrimary === 'var(--blue-net-zero)' ? tagText[1] : null}
      {colorPrimary === 'var(--deep-blue-space)' ? tagText[2] : null}
    </div>
  )
}

export default Tag
