import React, { useState, useEffect } from 'react'
import './HeaderSection.css'
import LuxUnpluggedLogo from '../../components/_SVG/LuxUnpluggedLogo'
import Menu from '../../components/Menu/Menu'
import MenuButton from '../../components/MenuButton/MenuButton'
import AppleColorIcon from '../../components/_SVG/AppleColorIcon'
import SpotifyColorIcon from '../../components/_SVG/SpotifyColorIcon'
import { Link, useLocation } from 'react-router-dom'
import CloseButton from '../../components/CloseButton/CloseButton'
import MobileMenuButton from '../../components/MobileMenuButton/MobileMenuButton'
import MobileMenu from '../../components/MobileMenu/MobileMenu'

const HeaderSection = () => {
  const locaction = useLocation()
  const [isShowMenuDesktop, setShowMenuDesktop] = useState(false)
  const [isShowMenuMobile, toggleShowMenuMobile] = useState(false)
  // const isShowMenuMobile = useStore((state) => state.isShowMenuMobile)
  // const toggleShowMenuMobile = useStore((state) => state.toggleShowMenuMobile)

  const showMenuDesktop = () => {
    setShowMenuDesktop(!isShowMenuDesktop)
  }

  const showMenuMobile = () => {
    toggleShowMenuMobile(!isShowMenuMobile)
  }

  useEffect(() => {
    toggleShowMenuMobile(false)
  }, [locaction.pathname])

  return (
    <header
      className={`HeaderSection ${isShowMenuDesktop ? 'showMenuDesktop' : ''} ${
        isShowMenuMobile === true ? 'showMenuMobileHeader' : ''
      }`}
    >
      <div className="podcast-list-menu" onClick={() => showMenuDesktop()}>
        <span className="mb-3" onClick={() => showMenuDesktop()}>
          <CloseButton />
        </span>
        <div className="podcast-list-menu-wrapper">
          <span onClick={() => showMenuDesktop()}>
            <Link to="/">
              <MenuButton index={'0'} oneColor={false} />
            </Link>
          </span>
          <span onClick={() => showMenuDesktop()}>
            <Link to="/business-podcast">
              <MenuButton
                index={'1'}
                title={'Business podcast'}
                colorPrimary={'var(--violet-business)'}
                colorSecondary={'var(--green-business)'}
              />
            </Link>
          </span>
          <span onClick={() => showMenuDesktop()}>
            <Link to="/net-zero-future-podcast">
              <MenuButton
                index={'2'}
                title={'NET ZERO FUTURE'}
                colorPrimary={'var(--blue-net-zero)'}
                colorSecondary={'var(--lime-net-zero)'}
              />
            </Link>
          </span>
          <span onClick={() => showMenuDesktop()}>
            <Link to="/dark-matter-uncovered-podcast">
              <MenuButton
                index={'3'}
                title={'DARK MATTER UNCOVERED'}
                colorPrimary={'var(--deep-blue-space)'}
                colorSecondary={'var(--lilac-space)'}
              />
            </Link>
          </span>
        </div>
      </div>

      <div className="header-desktop">
        <div className="header-container">
          <div className="menu-wrapper">
            <Menu onClick={() => showMenuDesktop()} />
          </div>
          <div className="header-logo">
            <Link to="/" aria-label="Home page">
              <LuxUnpluggedLogo width={106} index={'4'} oneColor={false} />
            </Link>
          </div>
          <div className="header-links">
            <AppleColorIcon className="AppleColorIcon me-3" />
            <SpotifyColorIcon className="SpotifyColorIcon" />
            <div className="mobile-menu-wrapper" onClick={showMenuMobile}>
              <MobileMenuButton className={`ms-3 ${isShowMenuMobile ? 'closeMenu' : ''}`} />
              <CloseButton opacity="1" />
            </div>
          </div>
        </div>
      </div>
      <div className="header-mobile">
        <MobileMenu />
      </div>
    </header>
  )
}

export default HeaderSection
