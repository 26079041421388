const CircleQuarter = ({
  color = 'var(--red-home)',
  width = 30,
  className = 'CircleQuarter',
  strokeWidth = 2,
  rotate = '0deg',
  opacity = '1',
}) => {
  const ratio = 30 / 30
  const height = width / ratio

  return (
    <div
      className={`SVG CircleQuarter ${className}`}
      style={{ width: width, height: height, transform: `rotate(${rotate})`, opacity: opacity }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 42 42">
        <path
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          d="M1.488 41.44c0-22.095 17.912-40.007 40.007-40.007"
        />
      </svg>
    </div>
  )
}

export default CircleQuarter
