import React, { useState, useEffect } from 'react'
import './HeroSection.css'
import CirclesPatternBig from '../../components/_SVG/CirclesPatternBig'
import TriangularGroup from '../../components/_SVG/TriangularGroup'
import HeroPersons from '../../components/HeroPersons/HeroPersons'
import SoundwaveLine from '../../components/_SVG/SoundwaveLine'
import { useLocation } from 'react-router-dom'
import { images_list_by_id } from '../../images'

const HeroSection = ({
  colorPrimary = 'var(--violet-business)',
  colorSecondary = 'var(--green-business)',
  isHome = false,
  title = 'LUXUNPLUGGED PODCAST',
  subtitle = 'DIVE INTO LUXEMBOURG',
  description = 'LuxUnplugged gets together with key leaders to unearth untold stories in Luxembourg',
  height = '900px',
  episodeNumber = '01',
  id = null,
}) => {
  let location = useLocation()

  const [episodePathName, setEpisodePathName] = useState(false)
  let imagesrc = ''
  if (
    id != null &&
    images_list_by_id[id] != undefined &&
    images_list_by_id[id].img_src != undefined
  ) {
    imagesrc = '/img/cover/' + images_list_by_id[id].img_src
  }
  // console.log('images_list_by_id', images_list_by_id)
  useEffect(() => {
    const episodePathNameChecker = () => {
      if (location.pathname.includes('episode/')) {
        return setEpisodePathName(true)
      } else {
        return setEpisodePathName(false)
      }
    }
    episodePathNameChecker()
  }, [location])
  return (
    <section className="Hero" style={{ backgroundColor: colorPrimary, height: height }}>
      <CirclesPatternBig
        isHome={isHome}
        colorPrimary={colorPrimary}
        colorSecondary={colorSecondary}
      />
      <HeroPersons
        isHome={isHome}
        colorPrimary={colorPrimary}
        episodeImageSrc={imagesrc}
        episodeImageAlt={title}
      />
      <div className="container position-relative">
        <div className="content row position-relative z-1">
          <div className="col-sm-12 col-md-8 col-lg-5 ms-lg-5">
            {episodePathName === true ? (
              <div className="episode-number mb-4">
                <span>Episode {episodeNumber}</span>
                <SoundwaveLine color={colorSecondary} lineWidth={80} strokeWidth="2.2" />
              </div>
            ) : null}
            <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
            <h2>{subtitle}</h2>
            <p className="mt-3">{description}</p>
          </div>
        </div>
        <TriangularGroup isHome={isHome} colorSecondary={colorSecondary} />
      </div>
    </section>
  )
}

export default HeroSection
