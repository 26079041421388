import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Page from './_Page/Page'
import HeroSection from '../sections/HeroSection/HeroSection'
import PlayerSection from '../sections/PlayerSection/PlayerSection'
import { getColorsBySeriesName } from '../cross-page-functions'
import MotionAnimation from '../components/Transition/MotionAnimation.jsx'
import { Helmet } from 'react-helmet-async'
import Section from '../sections/_Section/Section.jsx'
import {
  PodcastLeft,
  PodcastRight,
} from '../sections/PodcastDescriptionSection/PodcastDescriptionSection.jsx'
import MusicPlatformsSection from '../sections/MusicPlatformsSection/MusicPlatformsSection.jsx'
import FeaturedEpisodes from '../sections/FeaturedEpisodes/FeaturedEpisodes.jsx'
import Partners from '../sections/PartnersSection/Partners.jsx'
import FooterSection from '../sections/FooterSection/FooterSection.jsx'

const LUXUNPLUGGED_API = import.meta.env.VITE_PATH_TO_API

function EpisodePage({ previousColor, pathName, setEpisodeId }) {
  let { episode_id } = useParams()

  const [loaded, setLoaded] = useState(false)
  const [FeaturedEpisodesList, setFeaturedEpisodes] = useState([])
  const [episodeInfoLoaded, setEpisodeInfoLoaded] = useState(false)

  const [episodeInfo, setEpisodeInfo] = useState({
    formattedTitle: null,
    guest: null,
    series: null,
    episodeNumber: null,
    iframeurl: null,
    primaryColor: null,
    secondaryColor: null,
    id: null,
    description: null,
    description_text: null,
    title: null,
    keywords: null,
  })

  useEffect(() => {
    // getting information about the podcast by its id
    axios
      .get(LUXUNPLUGGED_API + '/episode/' + episode_id + '?formatted=true')
      .then((res) => {
        if (res.status == 200) {
          let [primary, secondary] = getColorsBySeriesName(res.data.data.series)
          let description_total = res.data.data.description
          let description_title = description_total.split('.')[0]
          if (description_title.substr(0, 5) === '<div>') {
            description_title = description_title.substr(5)
          }
          description_total = description_total
            .split('.')
            .splice(1, description_title.length - 1)
            .join('. ')
            .trim()

          setEpisodeInfo((currentEpisodeInfo) => {
            currentEpisodeInfo.episodeNumber = res.data.data.episode_number
            currentEpisodeInfo.formattedTitle = res.data.data.title
            currentEpisodeInfo.guest = res.data.data.guest
            currentEpisodeInfo.series = res.data.data.series
            currentEpisodeInfo.iframeurl = res.data.data.url.iframe
            currentEpisodeInfo.primaryColor = primary
            currentEpisodeInfo.secondaryColor = secondary
            //currentEpisodeInfo.description = res.data.data.description
            currentEpisodeInfo.description = description_total
            currentEpisodeInfo.title = description_title
            if (res.data.data.keywords != '') {
              currentEpisodeInfo.keywords = res.data.data.keywords
            }

            return currentEpisodeInfo
          })

          setEpisodeId(episode_id)

          setEpisodeInfoLoaded(true)
        }
      })
      .catch((err) => console.error(err))
  }, [])

  useEffect(() => {
    if (episodeInfo.series != null) {
      let pages = {
        'Business Podcast': 'business_podcast',
        'Dark Matter Uncovered': 'dark_matter_uncovered',
        'Net Zero Future': 'net_zero',
      }
      let page_id = pages[episodeInfo.series]
      axios
        .get(LUXUNPLUGGED_API + '/featured-episodes/' + page_id)
        .then((res) => {
          if (res.status == 200) {
            setFeaturedEpisodes(res.data.data)
            setLoaded(true)
          }
        })
        .catch((err) => console.error(err))
    }
  }, [episodeInfo, loaded, episodeInfoLoaded])

  const favicon =
    document.querySelector("link[rel='icon']") ||
    document.querySelector("link[rel='shortcut icon']")

  let faviconUrl = ''
  const setFaviconPath = () => {
    if (episodeInfo.series === 'Business Podcast') {
      return (faviconUrl = '/favicon-business.svg')
    } else if (episodeInfo.series === 'Net Zero Future') {
      return (faviconUrl = '/favicon-zero_net_future.svg')
    } else if (episodeInfo.series === 'Dark Matter Uncovered') {
      return (faviconUrl = '/favicon-dark_matter.svg')
    } else {
      return (faviconUrl = '/favicon.svg')
    }
  }

  useEffect(() => {
    setFaviconPath()
    favicon.href = faviconUrl
  }, [location.pathname, episodeInfo.series])

  if (loaded) {
    return (
      <>
        <Helmet>
          <title>{episodeInfo.formattedTitle}</title>
          {/* <meta name="description" content={episodeInfo.iframeurl} /> */}
          <meta
            name="description"
            content="LuxUnplugged Podcast: Explore Luxembourg's business landscape, space companies and initiatives, and environmental sustainability with Adrien Loesch and Thierry Schoen."
            data-rh="true"
          />
          <meta
            name="keywords"
            content="Luxunplugged podcast, Business Insights Luxembourg, Luxembourg Business Ecosystem, Entrepreneurship in Luxembourg, Space Companies Luxembourg, Adrien Loesch, Thierry Schoen, Kirill Mitsurov, Elena Sychkova"
          />
        </Helmet>
        <Page colorPrimary={episodeInfo.primaryColor} pathName={pathName}>
          <MotionAnimation color={episodeInfo.primaryColor} previousColor={previousColor} />
          <HeroSection
            colorPrimary={episodeInfo.primaryColor}
            colorSecondary={episodeInfo.secondaryColor}
            title={episodeInfo.guest}
            subtitle={episodeInfo.series}
            description={episodeInfo.formattedTitle}
            episodeNumber={episodeInfo.episodeNumber}
            id={episode_id}
          />
          <PlayerSection
            colorPrimary={episodeInfo.primaryColor}
            colorSecondary={episodeInfo.secondaryColor}
            loaded={loaded}
            iframeurl={episodeInfo.iframeurl}
            guestName={episodeInfo.guest}
            episodeNumber={episodeInfo.episodeNumber}
            id={episode_id}
          />
          <Section
            colorPrimary={episodeInfo.primaryColor}
            color="var(--white)"
            className="PodcastDescriptionSection"
            twoColumsLayout
            childrenLeftColumn={
              <PodcastLeft
                colorPrimary={episodeInfo.primaryColor}
                colorSecondary={episodeInfo.secondaryColor}
                description={episodeInfo.description}
                title={episodeInfo.title}
              />
            }
            childrenRightColumn={
              <PodcastRight
                colorPrimary={episodeInfo.primaryColor}
                colorSecondary={episodeInfo.secondaryColor}
                id={episode_id}
              />
            }
          />

          <Section color={episodeInfo.primaryColor} CirclesCropped overflowType={'hidden'}>
            <MusicPlatformsSection backgroundColor={episodeInfo.primaryColor} />
          </Section>

          <Section
            colorPrimary={episodeInfo.primaryColor}
            sectionTitle={'THE BEST'}
            className="pb-8"
          >
            <FeaturedEpisodes
              colorSecondary={episodeInfo.secondaryColor}
              FeaturedEpisodesList={FeaturedEpisodesList}
            />
          </Section>
          <Section
            colorPrimary={episodeInfo.primaryColor}
            color={'var(--white)'}
            className="Partners"
            sectionTitle={'ALL TOGETHER'}
          >
            <Partners colorSecondary={episodeInfo.secondaryColor} title="Our Partners" />
          </Section>
          <FooterSection />
        </Page>
      </>
    )
  }
  return <MotionAnimation color={episodeInfo.primaryColor} previousColor={previousColor} />
}
export default EpisodePage
//
