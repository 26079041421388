import CirclesPatternSmall from '../_SVG/CirclesPatternSmall'
import LuxUnpluggedLogo from '../_SVG/LuxUnpluggedLogo'
import './MenuButton.css'

const MenuButton = ({
  index,
  colorPrimary,
  colorSecondary,
  title = 'all podcasts',
  oneColor = true,
}) => {
  if (colorPrimary === undefined) {
    colorPrimary = 'var(--grey-home)'
  }
  if (colorSecondary === undefined) {
    colorSecondary = 'var(--light-grey-home)'
  }
  return (
    <div className="MenuButton mx-2 my-2" style={{ backgroundColor: colorPrimary }}>
      <LuxUnpluggedLogo oneColor={oneColor} index={index} width={120} />
      <span className="mt-2">{title}</span>
      <div className="bg">
        <CirclesPatternSmall
          width={500}
          rotate={'130deg'}
          index={index}
          colorPrimary={colorPrimary}
          colorSecondary={colorSecondary}
        />
      </div>
    </div>
  )
}

export default MenuButton
