import { motion } from 'framer-motion'
import LuxUnpluggedLogo from '../_SVG/LuxUnpluggedLogo'

const MotionAnimation = ({ color, previousColor }) => {
  return (
    <>
      <motion.div
        key="modal1"
        className="slide-in"
        initial={{
          x: '-100vw',
          backgroundColor: color,
        }}
        animate={{ x: '-100vw', backgroundColor: color }}
        exit={{ x: '0vw', backgroundColor: color }}
        transition={{ duration: 1.2, position: 'relative', ease: [0.19, 1, 0.22, 1] }}
      >
        <span style={{ opacity: '0.5', marginBottom: '4rem' }}>
          <LuxUnpluggedLogo oneColor width={120} />
        </span>
      </motion.div>
      <motion.div
        key="modal2"
        className="slide-out"
        initial={{
          x: '0vw',
          backgroundColor:
            previousColor === null || previousColor === undefined ? color : previousColor,
        }}
        animate={{ x: '100vw', backgroundColor: color }}
        exit={{
          x: '100vw',
          backgroundColor:
            previousColor === null || previousColor === undefined ? color : previousColor,
        }}
        transition={{ delay: '1.2', duration: 1.6, ease: [0.3, 1, 0.22, 1] }}
      >
        <span style={{ opacity: '0.5', position: 'relative', marginBottom: '4rem' }}>
          <LuxUnpluggedLogo oneColor width={120} />
        </span>
      </motion.div>
    </>
  )
}

export default MotionAnimation
