import CirclesCroppedGroup from '../../components/_SVG/CirclesCroppedGroup'
import './Section.css'

const Section = ({
  children,
  color = 'rgba(var(--black-code), 0.1)',
  className = '',
  sectionTitle,
  isFooter,
  colorPrimary = '',
  oneColumnLayout = false,
  twoColumsLayout,
  childrenLeftColumn,
  childrenRightColumn,
  CirclesCropped = false,
  overflowType = 'auto',
}) => {
  const colorPrimaryByCategory = (color) => {
    if (color === 'var(--deep-blue-space)') {
      return 'rgba(var(--deep-blue-space-code), 0.2)'
    } else if (color === 'var(--blue-net-zero)') {
      return 'rgba(var(--blue-net-zero-code), 0.2)'
    } else if (color === 'var(--violet-business)') {
      return 'rgba(var(--violet-business-code), 0.2)'
    } else {
      return 'rgba(var(--black-code), 0.1)'
    }
  }

  return (
    <section
      className={`Section ${className}`}
      style={{ backgroundColor: color, overflow: overflowType }}
    >
      {CirclesCropped ? (
        <CirclesCroppedGroup
          rotateG1={'-220deg'}
          rotateG2={'-90deg'}
          rotateG3={'-60deg'}
          width={'95%'}
          circlesOpacity={0.2}
          id={'01'}
        />
      ) : null}
      {sectionTitle ? (
        <span className="sectionTitle" style={{ color: colorPrimaryByCategory(colorPrimary) }}>
          {sectionTitle}
        </span>
      ) : null}
      {isFooter ? (
        <span className="sectionTitleFooter">
          <p className="me-sm-3 me-md-4 me-lg-5" style={{ color: 'var(--lilac-space)' }}>
            GET
          </p>
          <p className="me-sm-3 me-md-4 me-lg-5" style={{ color: 'var(--green-business)' }}>
            IN
          </p>
          <p style={{ color: 'var(--lime-net-zero)' }}>TOUCH</p>
        </span>
      ) : null}
      {oneColumnLayout === false || twoColumsLayout === false ? children : null}
      {oneColumnLayout === true ? (
        <div className="container position-relative">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-sm-12">{children}</div>
          </div>
        </div>
      ) : null}
      {twoColumsLayout === true ? (
        <div className="container position-relative mt-4">
          <div className="row">
            <div className="col-lg-8 col-sm-12">{childrenLeftColumn}</div>
            <div className="col-lg-4 col-sm-12">{childrenRightColumn}</div>
          </div>
        </div>
      ) : null}
    </section>
  )
}

export default Section
