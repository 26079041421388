function getColorsBySeriesName(series) {
  let primaryColor, secondaryColor
  let colorPrimaryList = {
    business: 'var(--violet-business)',
    netZero: 'var(--blue-net-zero)',
    darkMatterUncovered: 'var(--deep-blue-space)',
  }
  let colorSecondaryList = {
    business: 'var(--green-business)',
    netZero: 'var(--lime-net-zero)',
    darkMatterUncovered: 'var(--lilac-space)',
  }
  switch (series) {
    case 'Net Zero Future':
      primaryColor = colorPrimaryList.netZero
      secondaryColor = colorSecondaryList.netZero
      break
    case 'Dark Matter Uncovered':
      primaryColor = colorPrimaryList.darkMatterUncovered
      secondaryColor = colorSecondaryList.darkMatterUncovered
      break
    default:
      primaryColor = colorPrimaryList.business
      secondaryColor = colorSecondaryList.business
      break
  }
  return [primaryColor, secondaryColor]
}

function is_numeric(str) {
  return /^\d+$/.test(str)
}
function is_letter(char) {
  return char.length === 1 && char.match(/[a-z]/i)
}
function is_email(str) {
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  if (str.match(validRegex)) {
    return true
  } else {
    return false
  }
}

export { getColorsBySeriesName, is_numeric, is_letter, is_email }
