import CircleQuarter from '../_SVG/CircleQuarter'
import './CloseButton.css'

const CloseButton = ({ className = '', opacity = '0.3' }) => {
  return (
    <div className="CloseButton">
      <div className="x-part">
        <span className="one"></span>
        <span className="two"></span>
      </div>
      <div className="outline">
        <span>
          <CircleQuarter
            opacity={opacity}
            color={'var(--white)'}
            width={18}
            strokeWidth={4}
            rotate="0deg"
            className="one"
          />
          <CircleQuarter
            opacity={opacity}
            color={'var(--white)'}
            width={18}
            strokeWidth={4}
            rotate="-90deg"
            className="two"
          />
        </span>
        <span>
          <CircleQuarter
            opacity={opacity}
            color={'var(--white)'}
            width={18}
            strokeWidth={4}
            rotate="-270deg"
            className="three"
          />
          <CircleQuarter
            opacity={opacity}
            color={'var(--white)'}
            width={18}
            strokeWidth={4}
            rotate="180deg"
            className="four"
          />
        </span>
      </div>
    </div>
  )
}

export default CloseButton
