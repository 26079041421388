const TeamCircles = ({
  color = 'var(--violet-business)',
  width = 400,
  className = '',
  rotate = '0deg',
}) => {
  const ratio = 453.3 / 453.3
  const height = width / ratio
  return (
    <div
      className={`SVG TeamCircles ${className}`}
      style={{ width: width, height: height, transform: `rotate(${rotate})` }}
    >
      <svg viewBox="0 0 453.3 453.3" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity={0.3}
          d="M327.3,76.3c83.1,55.6,105.3,168,49.7,251.1c-55.6,83.1-168,105.3-251.1,49.7c-44.7-29.9-71.8-76.4-78.6-125.8"
          stroke={color}
          strokeWidth="14"
          strokeMiterlimit="10"
        />

        <path
          opacity={0.3}
          d="M136.7,361c74.2,49.7,174.6,29.8,224.3-44.4c39.7-59.3,35-135.4-6.4-188.9"
          stroke={color}
          strokeWidth="14"
          strokeMiterlimit="10"
        />

        <path
          d="M60.2,115.2c-61.6,91.9-36.9,216.4,55,277.9c91.9,61.6,216.4,36.9,277.9-55c61.6-91.9,36.9-216.4-55-277.9
          C246.2-1.4,121.7,23.3,60.2,115.2z"
          stroke={color}
          strokeWidth="14"
          strokeMiterlimit="10"
        />
        <path
          d="M226.7,446.3C105.3,446.3,7,348,7,226.7S105.3,7,226.7,7C348,7,446.3,105.3,446.3,226.7"
          stroke={color}
          strokeWidth="14"
          strokeMiterlimit="10"
        />
      </svg>
    </div>
  )
}

export default TeamCircles
