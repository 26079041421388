const DeezerIcon = ({ color = 'var(--white)', width = 80, className = '' }) => {
  const ratio = 80 / 80
  const height = width / ratio
  return (
    <div className={`SVG ${className}`} style={{ width: width, height: height }}>
      <a
        href="https://www.deezer.com/en/show/663732"
        aria-label="Go to Deezer podcast"
        target="_blanc"
      >
        <svg role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80" fill="none">
          <path
            fill={color}
            d="M40 0C17.914 0 0 17.914 0 40s17.914 40 40 40 40-17.914 40-40S62.086 0 40 0ZM21.023 56.605h-10.88v-3.272h10.88v3.272Zm0-4.253h-10.88V49.08h10.88v3.272Zm0-4.254h-10.88v-3.272h10.88v3.272Zm0-4.335h-10.88V40.49h10.88v3.272Zm0-4.254h-10.88v-3.272h10.88v3.272ZM33.21 56.605H22.33v-3.272h10.88v3.272Zm0-4.253H22.33V49.08h10.88v3.272Zm0-4.254H22.33v-3.272h10.88v3.272Zm12.188 8.507h-10.88v-3.272H45.4v3.272Zm0-4.253h-10.88V49.08H45.4v3.272Zm0-4.254h-10.88v-3.272H45.4v3.272Zm0-4.335h-10.88V40.49H45.4v3.272Zm0-4.254h-10.88v-3.272H45.4v3.272Zm0-4.335h-10.88v-3.272H45.4v3.272Zm0-4.254h-10.88v-3.272H45.4v3.272Zm12.27 25.685H46.708v-3.272h10.879l.082 3.272Zm0-4.253H46.708V49.08h10.879l.082 3.272Zm0-4.254H46.708v-3.272h10.879l.082 3.272Zm0-4.335H46.708V40.49h10.879l.082 3.272Zm0-4.254H46.708v-3.272h10.879l.082 3.272Zm12.188 17.096h-10.88v-3.272h10.88v3.272Zm0-4.253h-10.88V49.08h10.88v3.272Zm0-4.254h-10.88v-3.272h10.88v3.272Zm0-4.335h-10.88V40.49h10.88v3.272Zm0-4.254h-10.88v-3.272h10.88v3.272Zm0-4.335h-10.88v-3.272h10.88v3.272Zm0-4.254h-10.88v-3.272h10.88v3.272Zm-10.88-4.335v-3.272h10.88v3.272h-10.88Z"
          />
        </svg>
      </a>
    </div>
  )
}

export default DeezerIcon
